<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'advancePie'">
    <h5 *ngIf="config?.title" class="chartTitle">{{config?.title}}</h5>
    <h6 *ngIf="config?.subTitle" class="chartSubTitle">{{config?.subTitle}}</h6>
    <ngx-charts-advanced-pie-chart [view]="[1000, 220]" [scheme]="colors" [results]="data"
      [gradient]="config?.gradient" doughnut="false" [animations]="false">
    </ngx-charts-advanced-pie-chart>
    <h6 *ngIf="config?.footer" class="chartFooter">{{config?.footer}}</h6>
  </ng-container>

  <ng-container *ngSwitchCase="'barHorizontal'">
    <h5 *ngIf="config?.title" class="chartTitle">{{config?.title}}</h5>
    <h6 *ngIf="config?.subTitle" class="chartSubTitle">{{config?.subTitle}}</h6>
    <ngx-charts-bar-horizontal [ngClass]="config?.footer ? 'hasFooter': ''" [view]="[700, 450]" [scheme]="colors" [results]="data" [gradient]="config?.gradient"
      [xAxis]="config?.showXAxis" [yAxis]="config?.showYAxis" [legend]="config?.showLegend"
      [showXAxisLabel]="config?.showXAxisLabel" [showYAxisLabel]="config?.showYAxisLabel"
      [xAxisLabel]="config?.xAxisLabel" [yAxisLabel]="config?.yAxisLabel" [animations]="false">
    </ngx-charts-bar-horizontal>
    <h6 *ngIf="config?.footer" class="chartFooter">{{config?.footer}}</h6>
  </ng-container>

  <ng-container *ngSwitchCase="'barVertical'">
    <h5 *ngIf="config?.title" class="chartTitle">{{config?.title}}</h5>
    <h6 *ngIf="config?.subTitle" class="chartSubTitle">{{config?.subTitle}}</h6>
    <ngx-charts-bar-vertical [ngClass]="config?.footer ? 'hasFooter': ''" [view]="[700, 450]" [scheme]="colors" [results]="data" [gradient]="config?.gradient"
      [xAxis]="false" [yAxis]="config?.showYAxis" [legend]="config?.showLegend" [showXAxisLabel]="false"
      [showYAxisLabel]="config?.showYAxisLabel" [xAxisLabel]="config?.xAxisLabel" [yAxisLabel]="config?.yAxisLabel"
      [animations]="false">
    </ngx-charts-bar-vertical>
    <h6 *ngIf="config?.footer" class="chartFooter">{{config?.footer}}</h6>
  </ng-container>

  <ng-container *ngSwitchCase="'pieGrid'">
    <h5 *ngIf="config?.title" class="chartTitle">{{config?.title}}</h5>
    <h6 *ngIf="config?.subTitle" class="chartSubTitle">{{config?.subTitle}}</h6>
    <ngx-charts-pie-grid [scheme]="colors" [view]="config?.view" [results]="data" [animations]="false">
    </ngx-charts-pie-grid>
    <h6 *ngIf="config?.footer" class="chartFooter">{{config?.footer}}</h6>
  </ng-container>
</ng-container>