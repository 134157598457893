import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppLoggerService {
  log(message) {
    console.log(message); // debería utilizar NGXLogger
  }

  debug(message) {
    console.log(message);
  }
};
