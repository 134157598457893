import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TranslateModule } from '@ngx-translate/core';
import { WebcamModule } from 'ngx-webcam';
import { register } from 'swiper/element/bundle';

import { AntdModule } from 'src/app/antd.module';
import { AppCameraNgxComponent } from './ngx-camera/app-ngx-camera.component';
import { AppChartNgxComponent } from './ngx-chart/app-ngx-chart.component';
import { AppFacephiComponent } from './widgets/facephi/app-facephi.component';
import { AppIconComponent } from './widgets/icon/app-icon.component';
import { FooterComponent } from './footer/footer.component';
import { MenuTxnMainComponent } from './menu/menu-txn-main/menu-txn-main.component';
import { SwiperSlidesComponent } from './widgets/swiper-slides/swiper-slides.component';
import { TopbarEndUserMenuComponent } from './end-user-menu/end-user-menu.component';
import { TopbarUserMenuComponent } from './user-menu/user-menu.component';

import "@facephi/selphi-widget-web";
register();

const COMPONENTS = [
  AppCameraNgxComponent,
  AppChartNgxComponent,
  AppFacephiComponent,
  AppIconComponent,
  FooterComponent,
  MenuTxnMainComponent,
  SwiperSlidesComponent,
  TopbarEndUserMenuComponent,
  TopbarUserMenuComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AntdModule,
    FontAwesomeModule,
    NgScrollbarModule,
    NgxChartsModule,
    NgxDropzoneModule,
    PickerModule,
    TranslateModule,
    WebcamModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomComponentsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
