import { Injectable } from '@angular/core';
import axios from 'axios';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded } = defaultConfig.appEvents;
const gMapsGeolocUrl = `https://maps.googleapis.com/maps/api/geocode/json`;

@Injectable({ providedIn: 'root' })
export class GeocodeService {
  address: string = '';
  lat: number = 0;
  lng: number = 0;
  locationResult: string = '';
  googleApiKey: string = '';

  constructor(
    protected _appConfig: AppConfigService,
    protected _eventManager: EventManagerService,
  ) {
    _eventManager.subscribe(configLoaded.name, () => this.initGeocoder())
  }

  initGeocoder() {
    const googleApiKey = this._appConfig.getParameter('googleApiKey');
    if (googleApiKey) {
      this.googleApiKey = googleApiKey;
    }
  }

  async getCurrentPosition(): Promise<{ latitude: number; longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            reject('Error obteniendo la ubicación: ' + error.message);
          }
        );
      } else {
        reject('Geolocalización no soportada en este navegador.');
      }
    });
  }

  async geocodeLatLng(lat: number, lng: number): Promise<{ address: string } | null> {
    try {
      const response = await axios.get(gMapsGeolocUrl, { params: { latlng: `${lat},${lng}`, key: this.googleApiKey } });
      if (response.data.status === 'OK') {
        return { address: response.data.results[0].formatted_address };
      } else {
        console.error('Error en geocodificación inversa:', response.data.status);
        return null;
      }
    } catch (error) {
      console.error('Error en la solicitud de geocodificación inversa:', error);
      return null;
    }
  }

  async getLocation(address: string): Promise<{ latitude: number; longitude: number; address: string } | null> {
    try {
      const response = await axios.get(gMapsGeolocUrl, { params: { address, key: this.googleApiKey } });
      if (response.data.status === 'OK') {
        const location = response.data.results[0].geometry.location;
        const formattedAddress = response.data.results[0].formatted_address;
        return { latitude: location.lat, longitude: location.longitude, address: formattedAddress };
      } else {
        console.error('Error en geocodificación:', response.data.status);
        return null;
      }
    } catch (error) {
      console.error('Error en la solicitud de geocodificación:', error);
      return null;
    }
  }
}
