import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: 'app-facephi',
  templateUrl: './app-facephi.component.html',
  styles: [''],
})

export class AppFacephiComponent {
  @Output() facephiResult = new EventEmitter<any>();
  cameraWidth: number = 1280;
  cameraHeight: number = 720;

  onModuleLoaded() {
    console.log('onModuleLoaded');
  }

  onStabilizing(ev) {
    console.log('onStabilizing');
  }

  onExtractionFinish(ev) {
    const { detail } = ev
    if (detail) {
      let { bestImage, bestImageCropped, bestImageTokenized, sunGlassesScore, template, templateRaw } = detail;
      const facephiResult = {
        finishStatus: 1,
        template,
        templateRaw,
        images: '',
        errorType: '',
        errorMessage: '',
        bestImage: bestImage?.currentSrc?.split("base64,")[1],
        bestImageCropped: bestImageCropped?.currentSrc?.split("base64,")[1],
        eyeGlassesScore: sunGlassesScore,
        templateScore: '',
        qrData: '',
        bestImageTemplateRaw: bestImageTokenized,
      }
      this.facephiResult.emit(facephiResult);
    }
  }

  onUserCancel() {
    console.log('onUserCancel');
  }

  onExceptionCaptured(ev) {
    if (ev) {
      let errorMessage;
      const { detail: { message } } = ev;
      // if (message.includes("Error opening camera")) {
      //   errorMessage = "Error al intentar abrir la cámara";
      // } else 
      if (message.includes("Requested device not")) {
        errorMessage = "No hay cámara disponible";
      } else if (message.includes("Permission denied")) {
        errorMessage = "Autorice el uso de la cámara";
      }
      const facephiResult = {
        finishStatus: 2,
        errorMessage,
      }
      this.facephiResult.emit(facephiResult);
    }
  }

  onExtractionTimeout(ev) {
    if (ev) {
      const facephiResult = {
        finishStatus: 4,
      }
      this.facephiResult.emit(facephiResult);
    }
  }

  onTimeoutErrorButtonClick() {
    console.log('onTimeoutErrorButtonClick');
  }
}