import { Component, Injector } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';
import { defaultConfig } from 'src/app/app.config';

const { balanceUpdated, updateAgentBalance } = defaultConfig.appEvents;

@Component({
  selector: 'app-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: [
    './balance-card.component.scss',
    '../../../templates/form.scss',
  ]
})
export class BalanceCardComponent extends AppFormComponent {
  storeName: string = '';
  agentBalanceText: string = '';
  agentAccountBalanceDate: string = '';

  constructor(injector: Injector) {
    super(injector);
  }

  override preStart() { this.name = 'agentHome'; }

  override start() {
    super.start();
    this.subscribeAppEvents(balanceUpdated.name, balanceInfo => {
      if (!balanceInfo) { return; }
      this.storeName = balanceInfo?.storeName;
      this.agentBalanceText = balanceInfo?.agentBalanceText;
      this.agentAccountBalanceDate = balanceInfo?.agentAccountBalanceDate;
    });
  }

  updateAgentBalance() {
    this.emitAppEvents(updateAgentBalance.name, null);
  }
}
