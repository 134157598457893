import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserSessionService, activeSessionErrors } from './user-session.service';
import { EventManagerService } from './event-manager.service';
import { ComponentPageService } from './component-pages.service';
import { FormOperationService } from './form-manager.service';
import { defaultConfig } from 'src/app/app.config';

const { noSessionNext, notAuthorized, restartApp, sessionEnded } = defaultConfig.appEvents;

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  controlVar: any;
  constructor(
    private _userSession: UserSessionService,
    private _eventManager: EventManagerService,
    private _componentManager: ComponentPageService,
    private _formManager: FormOperationService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requestedRoute = state.url;
    const errorCode = await this._userSession.activeSession();
    const relatedForm = this._componentManager.routeForm(requestedRoute);
    let requiredFunctions = [];
    if (relatedForm) {
      const formDefinition = await this._formManager.getFormDefinition(relatedForm) ?? {};
      requiredFunctions = [...(formDefinition?.requiredFunctions ?? [])];
    }
    if (errorCode === activeSessionErrors.validSession) {
      if (!this._userSession.userCanNavigate(requestedRoute, requiredFunctions)) {
        this._eventManager.next(notAuthorized.name, null);
        return false;
      }
      return true;
    }
    if (errorCode === activeSessionErrors.expiredOnServer) {
      this._eventManager.next(sessionEnded.name, requestedRoute);
    } else if (errorCode === activeSessionErrors.concurrentSession) {
      this._eventManager.next(sessionEnded.name, null);
    } else if (errorCode === activeSessionErrors.noSession) {
      this._eventManager.next(noSessionNext.name, requestedRoute);
      this._eventManager.next(restartApp.name, null);
    } else {
      this._eventManager.next(restartApp.name, null);
    }
    return false;
  }
}
