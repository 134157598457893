import { environment } from '../environments/environment';

const ecosystemName = 'bolivariano';
const appName = 'Bolivariano';

const eventTypes = {
  behaviour: 'behaviour',
  subject: 'subject',
  reply: 'reply'
};

const appEvents = {
  // Estructura con { name, type, initialValue, windowTime, timestampProvider }
  agentStatus: { name: 'agentStatus', type: eventTypes.behaviour },
  appStarted: { name: 'appStarted', type: eventTypes.reply },
  balanceUpdated: { name: 'balanceUpdated', type: eventTypes.behaviour },
  chat: { name: 'chat', type: eventTypes.behaviour },
  chatMessage: { name: 'chatMessage', type: eventTypes.behaviour },
  chgTxnActiveGroup: { name: 'chgTxnActiveGroup', type: eventTypes.behaviour },
  closeInvasiveNotification: { name: 'closeInvasiveNotification', type: eventTypes.subject },
  communicationFailure: { name: 'communicationFailure', type: eventTypes.subject },
  compromisedDevice: { name: 'compromisedDevice', type: eventTypes.subject },
  configLoaded: { name: 'configLoaded', type: eventTypes.behaviour },
  enableNotifications: { name: 'enableNotifications', type: eventTypes.subject },
  endUserEndSession: { name: 'endUserEndSession', type: eventTypes.subject },
  endUserRequireSession: { name: 'endUserRequireSession', type: eventTypes.subject },
  endUserSessionActive: { name: 'endUserSessionActive', type: eventTypes.behaviour },
  endUserSessionEstablished: { name: 'endUserSessionEstablished', type: eventTypes.behaviour },
  endUserSessionObtained: { name: 'endUserSessionObtained', type: eventTypes.behaviour },
  endUserStartedInFavs: { name: 'endUserStartedInFavs', type: eventTypes.behaviour },
  endUserStartSession: { name: 'endUserStartSession', type: eventTypes.subject },
  formActivity: { name: 'formActivity', type: eventTypes.subject },
  invasiveNotification: { name: 'invasiveNotification', type: eventTypes.behaviour },
  keepSessionActive: { name: 'keepSessionActive', type: eventTypes.reply },
  menuObtained: { name: 'menuObtained', type: eventTypes.behaviour },
  messageRead: { name: 'messageRead', type: eventTypes.behaviour },
  noSessionNext: { name: 'noSessionNext', type: eventTypes.behaviour },
  notAuthorized: { name: 'notAuthorized', type: eventTypes.subject },
  notification: { name: 'notification', type: eventTypes.behaviour },
  notifyFormAfterSessionEnd: { name: 'notifyFormAfterSessionEnd', type: eventTypes.behaviour },
  notifyFormAfterSessionStart: { name: 'notifyFormAfterSessionStart', type: eventTypes.behaviour },
  pendingNotifications: { name: 'pendingNotifications', type: eventTypes.behaviour },
  pendingTickets: { name: 'pendingTickets', type: eventTypes.behaviour },
  pointedByApp: { name: 'pointedByApp', type: eventTypes.behaviour },
  profileObtained: { name: 'profileObtained', type: eventTypes.behaviour },
  restartApp: { name: 'restartApp', type: eventTypes.reply },
  serverProcessEnded: { name: 'serverProcessEnded', type: eventTypes.subject },
  serverProcessStarted: { name: 'serverProcessStarted', type: eventTypes.subject },
  sessionEncryptKeyReceived: { name: 'sessionEncryptKeyReceived', type: eventTypes.behaviour },
  sessionEnded: { name: 'sessionEnded', type: eventTypes.subject },
  sessionEstablished: { name: 'sessionEstablished', type: eventTypes.behaviour },
  sessionObtained: { name: 'sessionObtained', type: eventTypes.behaviour },
  sessionSignKeyReceived: { name: 'sessionSignKeyReceived', type: eventTypes.behaviour },
  sessionToBeClosed: { name: 'sessionToBeClosed', type: eventTypes.subject },
  startApp: { name: 'startApp', type: eventTypes.behaviour },
  storeServices: { name: 'storeServices', type: eventTypes.behaviour },
  txnGroupSelection: { name: 'txnGroupSelection', type: eventTypes.behaviour },
  updateAgentBalance: { name: 'updateAgentBalance', type: eventTypes.behaviour },
  updateLastActivityDate: { name: 'updateLastActivityDate', type: eventTypes.subject },
  updateNotifications: { name: 'updateNotifications', type: eventTypes.behaviour },
  updateTickets: { name: 'updateTickets', type: eventTypes.behaviour },
};

export const defaultConfig = {
  clientAppCode: 'agentWeb',
  apiRootPath: '/ws-webagent/',
  signatureKey: 'GIFHESNCHFDJDNDNCCN',
  mobile: false,
  ...environment.appConfig,
  // Configuración default para ser re-escrita al inicio
  deviceControl: true,
  initialForm: 'welcome',
  deviceVerification: true,
  applicationName: `Agentes ${appName}`,
  companyName: ecosystemName,
  devicePreffix: 'KMGEVNCV17h',
  liveConnection: false,
  sessionActivityNotify: 20000,
  sessionToBeClosedWarning: 20000,
  warningDuration: 15000,
  assetsBase: `assets/${ecosystemName}`,
  logoutPath: '',
  privatePolicy: 'https://www.bolivariano.com/privacy',
  googleApiKey: '',
  appEvents,
  settings: {
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left',
    routerAnimation: 'slideFadeinUp',
    menuColor: 'gray',
    theme: 'default',
    authPagesColor: 'white',
    primaryColor: '#019AA9',
    leftMenuWidth: 284.0,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
  },
};
