import { Component, Input } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';

const frm = {
  name: 'welcomeSlides',
  states: {
    slidesView: 'slidesView',
  },
  sections: {
    slides: 'slides',
  },
  subsections: {
    slides: 'slides',
  },
  fields: {
    slidesType: 'slidesType',
    slidesContent: 'slidesContent',
  },
  actions: {
    loadSlides: 'loadSlides',
  },
  tables: {},
};

@Component({
  selector: 'app-welcome-slides',
  templateUrl: './welcome-slides.component.html',
})

export class WelcomeSlidesComponent extends AppFormComponent {
  @Input() type: string = 'welcome';
  slideObjects: any[] = [];
  swiperProperties: any[] = [];
  visibleSwiper: boolean = false;

  override preStart() {
    this.name = frm.name;
  }

  override start() {
    super.start();
    this.setFieldValue(frm.fields.slidesType, this.type);
    this.onActionFinish(frm.actions.loadSlides, () => this.showSlides());
    this.startAction(frm.actions.loadSlides);
  }

  showSlides() {
    console.log('llegamos de loadSlides');
    this.visibleSwiper = true;
    const { formSlides = [], swiperProperties = {} } = this.getFieldValue(frm.fields.slidesContent);
    console.log({ formSlides, swiperProperties });
    this.slideObjects = formSlides ?? [];
    this.swiperProperties = swiperProperties ?? {};
  }
}