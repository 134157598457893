import { Component } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';

const frm = {
  name: 'frontendConfig',
  states: { configQuery: 'configQuery' },
  sections: { frontendConfig: 'frontendConfig' },
  subsections: { frontendConfig: 'frontendConfig' },
  fields: { clientConfig: 'clientConfig' },
  actions: { updateConfig: 'updateConfig' },
  tables: {},
};

@Component({
  selector: 'app-frontend-config',
  template: `<div></div>`,
})
export class AppFrontendConfigComponent extends AppFormComponent {
  override preStart() { this.name = 'frontendConfig'; }

  override start() {
    super.start();
    this._appConfig.updateConfiguration(this.getFieldValue(frm.fields.clientConfig) ?? {});
  }
}
