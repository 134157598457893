import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations';
import { defaultConfig } from 'src/app/app.config';

const {
  agentStatus,
  balanceUpdated,
  menuObtained,
  sessionEnded,
  sessionEstablished,
} = defaultConfig.appEvents;

const subMenuName = 'contextMobile';

@Component({
  selector: 'app-layout-services',
  templateUrl: './services.component.html',
  styleUrls: [
    './services.component.scss',
    '../../templates/form.scss',
  ],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutServicesComponent extends AppFormComponent {
  routerAnimation: string;
  validTime: boolean = true;
  validPosition: boolean = true;
  activeAccount: boolean = true;
  enabledRouter: boolean = true;
  menuVisible: boolean = false;
  viewBalance: boolean = false;
  menuData: any[];
  headerMenuItem: any;
  showAlert: boolean;
  header: string;
  message: string;
  userName: string;
  lastName: string;
  userFeeling: string;

  override preStart() {
    this.name = 'agentBalance';
  }

  override start() {
    super.start();
    this.headerMenuItem = {
      background: 'white',
      image: 'xxx',
      title: 'Nombre usuario'
    };
    this.viewBalance = this.getExtraInfo('viewBalance');
    this.buildMenu(this._userSession.getAppMenus());
    this.subscribeAppEvents(menuObtained.name, menuData => this.buildMenu(menuData));
    this.subscribeAppEvents(sessionEstablished.name, (sessionInfo) => {
      this.userName = sessionInfo?.sessionData?.name ?? '';
      this.lastName = sessionInfo?.sessionData?.lastName ?? '';
      this.userFeeling = this.userName?.toLowerCase() + ' ' + this.lastName?.toLowerCase()
    });
    this.subscribeAppEvents(agentStatus.name, (agentStatus) => { agentStatus && this.updateAgentStatus(agentStatus) });
    this.subscribeAppEvents(balanceUpdated.name, (agentBalance) => this.updateAgentBalance(agentBalance));
  }

  buildMenu(inputData) {
    const data = JSON.parse(JSON.stringify(inputData?.[subMenuName]));
    if (data) {
      this.menuData = this.preprocessMenudata(data);
    }
  }

  preprocessMenudata(menuData) {
    menuData?.forEach((item) => {
      if (item.icon) {
        let pageUrl = `/${this._componentPageService.formRoute(item.form)}`;
        item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
          ? [item.additionalInfo] : item.additionalInfo;
        item.additionalInfo?.forEach(segment => pageUrl += `/${segment}`);
        item.url = pageUrl;
        item.iconName = item.icon;
        item.key = item.form;
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
    });
    return menuData;
  }

  openItemForm(form) {
    if (form === 'logout') {
      this.showModalDialog('Cierre de sesión', '¿Está seguro que desea cerrar la sesión actual?',
        ['Aceptar', 'Cancelar'], (opt) => {
          if (opt === 0) {
            this.emitAppEvents(sessionEnded.name, { endDate: new Date() });
          }
        });
    } else {
      setTimeout(() => {
        this.menuVisible = false;
        this.openForm(form);
      }, 200);
    }
  }

  toggleMenuVisibility() {
    this.menuVisible = !this.menuVisible;
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    if (animation === this.routerAnimation) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path;
    }
  }

  updateAgentStatus(agentStatus) {
    const { validPosition, validTime, activeAccount } = agentStatus ?? {};
    this.validPosition = validPosition ?? this.validPosition;
    this.validTime = validTime ?? this.validTime;
    this.activeAccount = activeAccount ?? this.activeAccount;
    this.enabledRouter = this.validTime && this.validPosition && this.activeAccount;
  }

  updateAgentBalance(agentBalance) {
    this.activeAccount = agentBalance?.agentAccountActive ?? true;
    this.enabledRouter = this.validTime && this.validPosition && this.activeAccount;
  }
}
