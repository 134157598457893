import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

const DEFAULT_BODY_MESSAGE = 'Haga click o arrastre su archivo aqui';
const DEFAULT_FILE_TYPES = '*'; //  'application/pdf,image/*'
const DEFAULT_MAX_FILE_SIZE = 5000000;
const ERROR_DETAILS = {
  size: 'El archivo supera el tamaño máximo permitido',
};

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFileUploadComponent extends FieldComponent {
  callback: any = null;
  body: string = '';
  accept: string;
  multiple = false;
  maxFileSize: string = '';
  reader: any = null;
  files: File[] = [];

  override start() {
    super.start();
    const { bodyMessage, fileTypes = DEFAULT_FILE_TYPES, maxFileSize = DEFAULT_MAX_FILE_SIZE } = this.customAttributes();
    this.body = bodyMessage;
    this.accept = fileTypes;
    this.maxFileSize = maxFileSize;
    this.reader = new FileReader();
  }

  onSelect(event) {
    const reason = event?.rejectedFiles[0]?.reason;
    if (reason) {
      this.field.setError('99', ERROR_DETAILS[reason] ?? '');
    }
    this.files.push(...event.addedFiles);
    const file = this.files?.[0] ?? null;
    if (file) {
      this.reader.readAsDataURL(file);
      this.reader.onload = () => {
        this.value.set({
          fileName: file.name,
          fileType: file.type,
          fileContent: this.reader.result,
        });
        this.updateObject();
      }
    } else {
      this.value.set(null);
      this.updateObject();
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.value.set(null);
    this.updateObject();
  }

  override updateObject() {
    super.updateObject();
    this.field.value = this.value();
  }
}
