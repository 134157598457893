<div class="cardBox widgetCard">
  <div class="clearfix" style="color: #b4b4b4; display: grid;">
    <div style="grid-area: 1 / 1 / 2 / 2; text-align: center; height: 3rem">
      <h4
        style="font-family: relaway-bold, sans-serif; text-align: left; font-style: italic; color: #b4b4b4; line-height: 1.5rem; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical;">
        {{storeName}}</h4>
    </div>
    <div style="grid-area: 1 / 2 / 2 / 3; text-align: end; font-size: large; height: 3rem"
      (click)="updateAgentBalance()">
      <app-icon iconName="rotateRight" collection="awesome" size="lg"></app-icon>
    </div>
    <div style="grid-area: 2 / 1 / 3 / 2; text-align: left;">
      <h1
        style="margin: unset; font-family: 'arial', sans-serif; font-size: 28px; text-shadow: 0 0 1px #05465559; color: #707070;">
        ${{agentBalanceText}}</h1>
      <small style="font-family: 'relaway', sans-serif; font-size: .8rem;">Cupo disponible</small>
    </div>
    <div style="grid-area: 2 / 2 / 3 / 3; text-align: right; display: flex; align-items: end; justify-content: end;">
      <small
        style="font-family: roboto, sans-serif; font-weight: 500; font-size: .9rem; color: #707070;">{{agentAccountBalanceDate
        |
        date:
        'dd/MM/YY hh:mm:ss'}}</small>
    </div>
  </div>
</div>