import store from 'store';
import { createSelector, createFeatureSelector, Action, ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { environment } from 'src/environments/environment';

export const SET_STATE = '[Settings] Set State';
export const CHANGE_SETTING = '[Settings] Change Setting';

export class SetStateAction implements Action {
  readonly type = SET_STATE;
  constructor(public payload: object) { }
}

export class ChangeSettingAction implements Action {
  readonly type = CHANGE_SETTING;
  constructor(public payload: object) { }
}

export type Actions = SetStateAction | ChangeSettingAction;

function getStoredSettings(storedSettings: object) {
  const settings = {};
  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
}

const STORED_SETTINGS = getStoredSettings({
  // logo: defaultConfig.companyName,
  // Se debe obtener y actualizar de app-manager customAttributes settings
  locale: 'en-US',
  isSidebarOpen: false,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  menuLayoutType: 'left', // left, top, nomenu
  routerAnimation: 'slideFadeinUp', // none, slideFadeinUp, slideFadeinRight, Fadein, zoomFadein
  menuColor: 'gray', // white, dark, gray
  theme: 'default', // default, dark
  authPagesColor: 'white', // white, gray, image
  primaryColor: '#019AA9',
  leftMenuWidth: 284,
  isMenuUnfixed: false,
  isMenuShadow: false,
  isTopbarFixed: false,
  isGrayTopbar: false,
  isContentMaxWidth: false,
  isAppMaxWidth: false,
  isGrayBackground: false,
  isCardShadow: true,
  isSquaredBorders: false,
  isBorderless: false,
});

// default settings, if not exist in localStorage
export const initialState: object = { ...STORED_SETTINGS };

export function reducer(state = initialState, action: Actions): object {
  if (action.type === SET_STATE) {
    const key = Object.keys(action.payload)[0];
    window.localStorage.setItem(`app.settings.${key}`, action.payload[key]);
    return { ...state, ...action.payload };
  } else {
    return state;
  }
}

export const reducers: ActionReducerMap<any> = {
  router: fromRouter.routerReducer,
  settings: reducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action);
    console.groupEnd();
    return result;
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

export const getSettingsState = createFeatureSelector<any>('settings');

export const getSettings = createSelector(getSettingsState, (state: any) => state);
