import { Component, computed, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

const LABEL_FLOATING = 'floating';
const FIELD_OUTLINE = 'outline';
const REQUIRED_MARK = ' *';

@Component({
  selector: 'app-simple-field',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSimpleField extends FieldComponent {
  displayMode: string = '';
  fieldAttributes: any = {};

  fieldClass = computed(() => this.customAttributes().fieldClass);
  labelPosition = computed(() => {
    return this.customAttributes().labelPosition ?? this.field._formConfig.defaultFieldAttributes?.labelPosition;
  });
  fieldLines = computed(() => {
    return this.customAttributes().lines ?? this.field._formConfig.defaultFieldAttributes?.lines;
  });
  inputFill = computed(() => {
    return this.customAttributes().fill ?? this.field._formConfig.defaultFieldAttributes?.inputFill;
  });
  labelVisible = computed(() => {
    return this.visibleLabel()
      && !this.formConfig?.notLabeledFields?.includes(this.type())
      && !this.formConfig?.notLabeledFields?.includes(this.captureType());
  });
  externalLabel = computed(() => {
    return this.labelPosition() !== LABEL_FLOATING && this.inputFill() === FIELD_OUTLINE;
  });
  labelText = computed(() => {
    const requiredMark = this.required() ? REQUIRED_MARK : '';
    return this.labelVisible() && this.externalLabel() ? (this.title() + requiredMark) : '';
  });

  fieldLabelText = computed(() => {
    return !this.externalLabel() && this.labelText() ? this.labelText() : undefined;
  });
  fieldLabelPosition = computed(() => {
    return !this.externalLabel() ? this.labelPosition() : undefined;
  });
  labelClass = computed(() => {
    let labelClass = this.labelText() ? 'labelField' : '';
    labelClass += ` ${this?.customAttributes().labelClass ?? ''}`;
    return labelClass;
  });

  override start() {
    super.start();
    this.fieldAttributes = this.field._formConfig.defaultFieldAttributes ?? {};
  }
}
