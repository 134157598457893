import { Component, computed, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-field',
  templateUrl: './app-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFieldComponent extends FieldComponent {
  labelVisible: boolean;
  labelSpan: number;
  fieldSpan: number;
  layout: string = 'inline';
  widgetType = computed(() => this.defineLayout());

  override start() {
    super.start();
    this.defineLayout();
  }

  defineLayout() {
    const { layout, showLabel: customVisible } = this.customAttributes();
    const { defaultFieldsLayout, notLabeledFields, spanLayout, widgets } = this.formConfig;

    this.layout = layout ?? defaultFieldsLayout;
    const defaultLabelVisible = this.visibleLabel()
      && !notLabeledFields.includes(this.type())
      && !notLabeledFields.includes(this.captureType());
    this.labelVisible = customVisible ?? defaultLabelVisible;
    this.labelSpan = spanLayout?.[this.layout]?.label
      ?? spanLayout?.default?.label ?? 8;
    this.fieldSpan = (this.labelVisible) ? (spanLayout?.[this.layout]?.field
      ?? spanLayout?.default?.field ?? 24) : 24;
    const widgetType = widgets.byCapture[this.captureType()] ?? widgets.byType[this.type()] ?? 'INPUT';
    if (widgetType === 'OTPINPUT') {
      this.fieldSpan = 24;
    }
    return widgetType;
  }
}
