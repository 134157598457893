import { Injectable } from '@angular/core';
import { LibEventManagerService } from 'tuain-ng-forms-lib';
import { defaultConfig } from '../app.config';

@Injectable({ providedIn: 'root' })
export class EventManagerService extends LibEventManagerService {
  constructor() {
    super(Object.values(defaultConfig.appEvents));
  }
}
