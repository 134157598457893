import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { NzImageService } from 'ng-zorro-antd/image';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { defaultConfig } from '../../../../app.config';

const { configLoaded } = defaultConfig.appEvents;

@Component({
  selector: 'app-qrcode',
  templateUrl: './app-qrcode.component.html',
  styleUrls: ['./app-qrcode.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppQRCodeComponent extends FieldComponent {
  assetsBase: string;

  constructor(
    private nzImageService: NzImageService,
    private _eventManager: EventManagerService,
    private _appConfig: AppConfigService,
  ) {
    super();
  }

  override start() {
    super.start();
    this._eventManager.subscribe(configLoaded.name, () => this.assetsBase = this._appConfig.getParameter('assetsBase'));
  }

  preview() {
    this.nzImageService.preview([{ src: this.value() }], { nzZoom: 1.5, nzRotate: 0 });
  }
}
