import { Component, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GoogleMap, MapMarker, MapInfoWindow } from '@angular/google-maps';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-map',
  templateUrl: './app-map.component.html',
})
export class AppMapComponent extends FieldComponent {
  latitude: number;
  longitude: number;

  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  @Input() editable = true;
  @Input() zoom = 16;
  marker: any;
  infoContent = '';

  center: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  override start() {
    super.start();
    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   };
    //   this.marker = {
    //     position: {
    //       lat: this.center.lat,
    //       lng: this.center.lng,
    //     },
    //     options: { animation: google.maps.Animation.BOUNCE },
    //   }
    // });
    this.updateValue();
  }

  override updateValue() {
    super.updateValue();
    const [latitude, longitude] = this.value();
    if (latitude && longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.center = {
        lat: +latitude,
        lng: +longitude,
      }
      this.marker = {
        position: {
          lat: +latitude,
          lng: +longitude,
        },
      }
      this.cdr.detectChanges();
    }
  }

  zoomIn() {
    if (this.zoom < this.mapOptions.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.mapOptions.minZoom) this.zoom--;
  }

  logCenter() {
    console.log('logCenter');
  }

  clickOnMap(event) {
    if (!this.editable || !this.enabled()) { return; }
    this.value.set([+(event?.latLng?.lat() ?? 0), +(event?.latLng?.lng() ?? 0)]);
    this.latitude = this.value()?.[0];
    this.longitude = this.value()?.[1];
    this.center = {
      lat: +this.latitude,
      lng: +this.longitude,
    }
    this.marker = {
      position: {
        lat: this.latitude ?? this.center.lat,
        lng: this.longitude ?? this.center.lng,
      },
      options: { animation: google.maps.Animation.BOUNCE },
    }
    this.value.set([this.latitude, this.longitude]);
    this.updateObject();
    this.onChangeContent();
  }

  openInfo(marker: MapMarker, content) {
    this.infoContent = content;
    this.infoWindow.open(marker);
  }

  inputChanged2() {
    this.value.set([this.latitude, this.longitude]);
    this.inputChanged();
  }
}
