import { Injectable } from '@angular/core';
import { EventManagerService } from './event-manager.service';
import { io } from 'socket.io-client';
import { defaultConfig } from 'src/app/app.config';

const { sessionEstablished } = defaultConfig.appEvents;

const MESSAGE = 'message';
const CLIENT_EVENT = 'clientEvent';
const DISCONNECT_EVENT = 'disconnect';

@Injectable({ providedIn: 'root' })
export class LiveConnectionService {
  socket: any;
  baseUrl: string;

  constructor(
    private _eventManager: EventManagerService) {
    this.baseUrl = this.setBaseUrlApi(defaultConfig?.serverEntryPoint);
    this._eventManager.subscribe(sessionEstablished.name, data => {
      if (data && defaultConfig?.liveConnection) {
        this.openLiveConnection(data);
      }
    });
  }

  setBaseUrlApi(serverEntryPoint) {
    let protocol = serverEntryPoint.protocol || window.location.protocol;
    protocol = protocol.endsWith(':') ? protocol.slice(0, -1) : protocol;
    let server = serverEntryPoint.server || window.location.hostname;
    server = server.endsWith('/') ? server.slice(0, -1) : server;
    let baseUrl = `${protocol}://${server}`;
    baseUrl += serverEntryPoint.port ? `:${serverEntryPoint.port}` : '';
    return baseUrl;
  }

  async openLiveConnection(session) {
    const sessionData = session?.sessionData;
    if (!sessionData) { return false; }
    this.socket = io(this.baseUrl, { path: '/liveconnect' });
    this.socket.emit(MESSAGE, sessionData);
    this.socket.on(CLIENT_EVENT, (eventData) => {
      console.log(`Se recibió el evento ${JSON.stringify(eventData)}`);
      this._eventManager.next(eventData?.eventClass, eventData);
    });
    this.socket.on(DISCONNECT_EVENT, (x) => {
      console.log('Se desconectó del server', x);
    });
  }
}
