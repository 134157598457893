export const formConfig = {
  defaultFieldValues: {
    phone: 593,
  },
  defaultFieldAttributes: {
    // Común a todos los campos
    lines: 'none', // inset full none 
    labelPosition: 'stacked', // stacked floating NO USAR fixed, queda dentro 
    inputFill: 'outline', // outline
    inputMode: 'md', // "ios" | "md"'
    // Select
    selectInterface: 'action-sheet', // "action-sheet" ｜ "alert" ｜ "popover"
    selectMode: 'md', // "ios" | "md"'
    selectOkText: 'Aceptar',
    selectCancelText: 'Cancelar',
    // Search
    showClearButton: 'focus', // 'focus' | 'always' | 'never' | 'always' | 
    searchDebounce: 1300,
    searchEnterKeyHint: 'done', // "done" ｜ "enter" ｜ "go" ｜ "next" ｜ "previous" ｜ "search" ｜ "send" ｜ undefined
    searchInputmode: 'text', //	"decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url" | undefined
    searchMode: 'ios', // "ios" | "md"'
    dateFormat: 'dd/MM/yyyy',
    tableDateFormat: 'DD/MM/YYYY',
    tableMomentFormat: 'DD-MM-YYYY HH:mm',
  },
  defaultActionAttributes: {
    shape: 'round', // round default
    type: '', // inline
    fill: 'solid',
    expand: 'block',
    iconSlot: 'start',
  },
  fieldTypes: {
    alpha: 'ALPHA',
    array: 'ARRAY',
    avatar: 'AVATAR',
    boolean: 'BOOLEAN',
    check: 'CHECK',
    creditCard: 'CREDITCARD',
    currency: 'CURRENCY',
    ciiu: 'CIIU',
    city: 'CITY',
    date: 'DATE',
    dateMonth: 'DATEMONTH',
    daterange: 'DATERANGE',
    decimal: 'DECIMAL',
    email: 'EMAIL',
    html: 'HTML',
    image: 'IMAGE',
    integer: 'INTEGER',
    label: 'LABEL',
    summaryFields: 'SUMMARYFIELD',
    link: 'LINK',
    map: 'MAP',
    message: 'MESSAGE',
    number: 'NUMBER',
    password: 'PASSWORD',
    pdf: 'PDF',
    phone: 'PHONE',
    text: 'TEXT',
    time: 'TIME',
    timerange: 'TIMERANGE',
    timerangepckrs: 'TIMERANGEPCKRS',
    timerangeSlider: 'TIMERANGESLIDER',
    title: 'TITLE',
    warning: 'WARNING',
    yearMonth: 'YEARMONTH',
  },
  arrayFieldTypes: {
    ARRAY: '*',
    PHONE: 2,
    TIMERANGEPCKRS: 2,
    TIMERANGESLIDER: 2,
  },
  captureTypes: {
    barCode: 'BARCODE',
    check: 'CHECK',
    CHIP: 'CHIP',
    ciiu: 'CIIU',
    city: 'CITY',
    country: 'COUNTRY',
    image: 'IMAGE',
    input: 'INPUT',
    inputSearch: 'INPUTSEARCH',
    message: 'MESSAGE',
    list: 'LIST',
    otpInput: 'OTPINPUT',
    pdf: 'PDF',
    phone: 'PHONE',
    progress: 'PROGRESS',
    qrCode: 'QRCODE',
    radio: 'RADIO',
    select: 'SELECT',
    segment: 'SEGMENT',
    search: 'SEARCH',
    textArea: 'TEXTAREA',
    typeahead: 'TYPEAHEAD',
    yearMonth: 'YEARMONTH',
  },
  captureTypesWithOptions: ['LIST', 'RADIO', 'ARRAY', 'SEGMENT', 'CHIP'],
  notLabeledFields: ['LABEL', 'MESSAGE', 'TITLE', 'HTML', 'LINK', 'WARNING', 'IMAGE', 'SEARCH', 'SEGMENT', 'PDF'],
  widgets: {
    byType: {
      GEOLOCATION: 'MAP',
      ALPHA: 'INPUT',
      ARRAY: 'ARRAY',
      AVATAR: 'AVATAR',
      BOOLEAN: 'CHECK',
      CREDITCARD: 'NUMBER',
      CURRENCY: 'NUMBER',
      CITY: 'CITY',
      DATE: 'DATE',
      DATEMONTH: 'DATEMONTH',
      DATERANGE: 'DATERANGE',
      DECIMAL: 'NUMBER',
      EMAIL: 'EMAIL',
      HTML: 'HTML',
      IMAGE: 'IMAGE',
      INTEGER: 'NUMBER',
      LABEL: 'LABEL',
      SUMMARYFIELD: 'SUMMARYFIELD',
      LINK: 'LINK',
      MAP: 'MAP',
      MESSAGE: 'LABEL',
      NUMBER: 'NUMBER',
      PDF: 'PDF',
      TIME: 'TIME',
      TIMERANGE: 'TIMERANGE',
      TIMERANGEPCKRS: 'TIMERANGEPCKRS',
      TIMERANGESLIDER: 'TIMERANGESLIDER',
      TITLE: 'LABEL',
      WARNING: 'WARNING',
      YEARMONTH: 'YEARMONTH',
    },
    byCapture: {
      ALPHANUM: 'ALPHANUM',
      BARCODE: 'BARCODE',
      CHECK: 'CHECK',
      CHIP: 'CHIP',
      CIIU: 'CIIU',
      CITY: 'CITY',
      COUNTRY: 'COUNTRY',
      CURRENCY: 'CURRENCY',
      FILECONTENT: 'FILECONTENT',
      INPUTSEARCH: 'INPUTSEARCH',
      LABEL: 'LABEL',
      LIST: 'LIST',
      MAP: 'MAP',
      MESSAGE: 'MESSAGE',
      OTPINPUT: 'OTPINPUT',
      PASSWORD: 'PASSWORD',
      PHONE: 'PHONE',
      PROGRESS: 'PROGRESS',
      QRCODE: 'QRCODE',
      RADIO: 'RADIO',
      SEARCH: 'SEARCH',
      SEGMENT: 'SEGMENT',
      TEXTAREA: 'TEXTAREA',
      TEXTNUMBER: 'TEXTNUMBER',
      TEXTFORMAT: 'TEXTFORMAT',
      TITLE: 'LABEL',
      SUMMARYFIELD: 'LABEL',
      TYPEAHEAD: 'TYPEAHEAD',
      WARNING: 'WARNING',
      YEARMONTH: 'YEARMONTH',
    },
  },
  fieldValidations: {
    EMAIL: {
      type: 'regexp',
      // Expresión cambiada  /^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,}$/i, o new RegExp('^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$'),
      validation: /^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,}$/i,
      message: 'El valor no corresponde a un correo válido',
    },
    ALPHA: {
      type: 'regexp',
      // mejora en validation: /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]+$/ o new RegExp('^([a-zA-Z]|[à-ú]|[À-Ú])+$'),
      validation: /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]+$/,
      message: 'El valor no se ajusta al formato establecido',
    },
    PHONE: {
      type: 'function',
      validation: (value, field) => {
        const minLength = field?.minLength || 10;
        const [country, number] = (value && Array.isArray(value)) ? value : [null, null];
        const valid = country && (number?.length >= minLength);
        let message = null;
        if (!valid) {
          message = !country ? 'País inválido' : `La longitud del número debe ser al menos ${minLength}`;
        }
        return { valid, message };
      },
      message: 'La longitud mínima del número debe ser 10',
    },
    DEFAULT: {
      type: 'regexp',
      validation: null,
      message: 'El valor no se ajusta al formato establecido',
    },
    TIMERANGE: {
      type: 'function',
      validation: (value, field) => {
        const [startTime, entTime] = (value && Array.isArray(value)) ? value : [null, null];
        const valid = (startTime && entTime) || (!startTime && !entTime);
        let message = null;
        if (!valid) {
          message = !startTime ? 'Hora inicial requerida' : 'Hora final requerida';
        }
        return { valid, message };
      },
      message: 'El rango de horas no está completo',
    },
    TIMERANGEPCKRS: {
      type: 'function',
      validation: (value, field) => {
        const [startTime, endTime] = (value && Array.isArray(value)) ? value : [null, null];
        const valid = (startTime && endTime) || (!startTime && !endTime);
        let message = null;
        if (!valid) {
          message = !startTime ? 'Hora inicial requerida' : 'Hora final requerida';
        }
        return { valid, message };
      },
      message: 'El rango de horas no está completo',
    },
  },
  propagationCustomAttributes: {
    fields: ['lines', 'fill', 'labelPosition', 'fieldClass'],
    actions: ['buttonType', 'buttonFill', 'buttonExpand'],
    sections: [],
    subsections: [],
  },
  fieldPropagateAttributes: {
    captureType: 'captureType',
    errorCode: 'errorCode',
    errorMessage: 'errorMessage',
    errorType: 'errorType',
    defaultValue: 'defaultValue',
    defaultEditable: 'defaultEditable',
    alignment: 'alignment',
    code: 'code',
    info: 'info',
    required: 'required',
    title: 'title',
    type: 'type',
    format: 'format',
    options: 'options',
    hasChanged: 'hasChanged',
    maxLength: 'maxLength',
    maxValue: 'maxValue',
    minLength: 'minLength',
    minValue: 'minValue',
    validating: 'onValidation',
    outputOnly: 'outputOnly',
    tooltip: 'tooltip',
    validateOnServer: 'validateOnServer',
    value: 'value',
    visibleLabel: 'visibleLabel',
    visible: 'visible',
    disabled: 'disabled'
  },
  actionPropagateAttributes: {
    actionCode: 'code',
    visible: 'visible',
    disabled: 'disabled'
  },
  tableColumnPropagateAttributes: ['code', 'visible', 'disabled'],
  tablePropagationAttributes: {
    code: 'code',
    globalFilterString: 'globalFilterString',
    visibleRecords: 'visibleRecords',
  },
  sectionPropagateAttributes: ['code', 'visible', 'disabled'],
  subSectionPropagateAttributes: ['code', 'visible', 'disabled'],
  tableActions: {
    sort: 'SORT',
    refresh: 'REFRESH',
    navigate: 'NAVIGATE',
    inline: 'INLINE',
    global: 'GLOBAL',
    rowSelection: 'ROWSELECTION',
    selection: 'SELECTION',
  },
  defaultRecordsPerPage: 10,
  defaultFieldsLayout: 'inline',
  spanLayout: {
    inline: {
      label: 8,
      field: 16,
    },
    wide: {
      label: 6,
      field: 18,
    },
    vertical: {
      label: 24,
      field: 24,
    },
    default: {
      label: 8,
      field: 16,
    },
  },

  errorTypes: {
    success: 'success',
    warning: 'warning',
    error: 'danger',
    info: 'info',
  },
  formStandardErrors: {
    validationCode: '13',
    typeWarning: 'WARNING',
    requiredFields: 'Todos los campos requeridos deben ser diligenciados',
    validationTitle: 'Advertencia',
    validationFields: 'Existen campos con problemas de validación',
    requiredField: 'Campo requerido',
  },

  tableFieldStyles: {
    TEXT: { 'text-align': 'left' },
    TITLE: { 'text-align': 'left' },
    LABEL: { 'text-align': 'left' },
    MESSAGE: { 'text-align': 'left' },
    NUMBER: { 'text-align': 'right' },
    DATE: { 'text-align': 'right' },
    CHECK: { 'text-align': 'center' },
    ARRAY: { 'text-align': 'left' },
    SELECT: { 'text-align': 'left' },
  },
};

export const componentConstants = {
  backgroungImages: 30.0,
  EMPTY: '',
  recordsPerPage: 10,
  crudListPagesGroup: 10,
  crudNavVisiblePages: 3,
  STD_ACTION_SAVE: 'SAVE',
  STD_ACTION_EDIT: 'EDIT',
  DIALOG_OK: 'Aceptar',
  DIALOG_CANCEL: 'Cancelar',
  DIALOG_UPDATE: 'Actualizar',
  DIALOG_EXIT: 'Salir',
  DIALOG_CONTINUE: 'Continuar',
  // Textos en dialogos modales en errores de proceso de Crud
  UPLOAD_TITLE: 'Cargue de Archivo',
  UPLOAD_BODY: 'Seleccione el archivo que desea cargar',
  LOADFORM_ERROR_TITLE: 'Error Obtención definición formulario',
  COMMUNICATION_ERROR_TITLE: 'Error de comunicación',
  COMMUNICATION_ERROR_BODY: 'No fue posible acceder al recurso solicitado',
  SESSIONENDED_ERROR_TITLE: 'Sesión Terminada',
  SESSIONENDED_ERROR_BODY:
    'Se ha detectado que su sesión ha caducado. Es necesario que ingrese nuevamente sus credenciales',
  LOADFORM_ERROR_BODY: 'Se produjo un error al obtener la definición ',
  FORMERROR_VALIDATION_CODE: '13',

  FORMERROR_TYPE_INFO: 'INFO',
  FORMERROR_TYPE_SUCCESS: 'SUCCESS',
  FORMERROR_TYPE_WARNING: 'WARNING',
  FORMERROR_TYPE_DANGER: 'DANGER',
  FORMERROR_REQUIRED_FIELDS: 'Todos los campos requeridos deben ser diligenciados',
  FORMERROR_VALIDATION_TITLE: 'Advertencia',
  FORMERROR_VALIDATION_FIELDS: 'Existen campos con problemas de validación',
  FORMACTION_ERROR_TITLE: 'Error ejecutando acción en el Servidor',
  FORMACTION_ERROR_BODY: 'Acción solicitada no está definida',
  FORMUPDATE_ERROR_TITLE: 'Error en Actualización de datos',
  FORMUPDATE_ERROR_BODY: 'Se produjo un error al obtener las datos del registro',
  // Valores para los controles de navegació
  FORMNAVIGATION_FIRST: 'first',
  FORMNAVIGATION_PREVGROUP: 'prevgroup',
  FORMNAVIGATION_NEXTGROUP: 'nextgroup',
  FORMNAVIGATION_LAST: 'last',

  // Acciones de formularios
  FORMACTION_TABLEACTION: 'TABLEACTION',
  FORMACTION_GETDATA: 'GETDATA',
  FORMACTION_GETTABLEDATA: 'GETTABLEDATA',
  FORMACTION_VALIDATE: 'VALIDATE',

  // Modos o estados de formularios
  FORMMODE_CREATE: 'CREATE',
  FORMMODE_EDIT: 'EDIT',
  FORMMODE_VIEW: 'VIEW',

  // Constantes para el despliegue de formularios
  ELEMENTTYPE_ACTION: 'ACTION',
  ELEMENTTYPE_ACTIONGROUP: 'ACTIONGROUP',
  ELEMENTTYPE_FIELD: 'FIELD',
  ELEMENTTYPE_TABLE: 'TABLE',

  FIELDTYPES: [
    'ARRAY',
    'CHECK',
    'DATE',
    'DATEMONTH',
    'DATERANGE',
    'TIME',
    'TIMERANGE',
    'TIMERANGEPCKRS',
    'TIMERANGESLIDER',
    'MAP',
    'NUMBER',
    'DECIMAL',
    'CURRENCY',
    'SELECT',
    'TEXT',
    'PASSWORD',
    'LABEL',
    'SUMMARYFIELD',
    'HTML',
    'TITLE',
    'MESSAGE',
    'LINK',
    'WARNING',
    'AVATAR',
  ],
  FIELDTYPE_ARRAY: 'ARRAY',
  FIELDTYPE_CHECK: 'CHECK',
  FIELDTYPE_DATE: 'DATE',
  FIELDTYPE_DATEMONTH: 'DATEMONTH',
  FIELDTYPE_DATERANGE: 'DATERANGE',
  FIELDTYPE_IMAGE: 'IMAGE',
  FIELDTYPE_TIME: 'TIME',
  FIELDTYPE_TIMERANGE: 'TIMERANGE',
  FIELDTYPE_TIMERANGEPCKRS: 'TIMERANGEPCKRS',
  FIELDTYPE_TIMERANGESLIDER: 'TIMERANGESLIDER',
  FIELDTYPE_MAP: 'MAP',
  FIELDTYPE_NUMBER: 'NUMBER',
  FIELDTYPE_DECIMAL: 'DECIMAL',
  FIELDTYPE_CURRENCY: 'CURRENCY',
  FIELDTYPE_SELECT: 'SELECT',
  FIELDTYPE_TEXT: 'TEXT',
  FIELDTYPE_TEXTAREA: 'TEXTAREA',
  FIELDTYPE_PASSWORD: 'PASSWORD',
  FIELDTYPE_LABEL: 'LABEL',
  FIELDTYPE_HTML: 'HTML',
  FIELDTYPE_TITLE: 'TITLE',
  FIELDTYPE_MESSAGE: 'MESSAGE',
  FIELDTYPE_LINK: 'LINK',
  FIELDTYPE_WARNING: 'WARNING',
  FIELDTYPE_AVATAR: 'AVATAR',
  CAPTURETYPE_PHONE: 'PHONE',
  CAPTURETYPE_EMAIL: 'EMAIL',

  FIELDTYPE_CHECK_THUMBNAIL: 'CHECK_THUMBNAIL',
  FIELDTYPE_THUMBNAIL: 'THUMBNAIL',

  FORMTABLEACTION_SORT: 'SORT',
  FORMTABLEACTION_REFRESH: 'REFRESH',
  FORMTABLEACTION_NAVIGATE: 'NAVIGATE',
  FORMTABLEACTION_INLINE: 'INLINE',

  FORMTABLE_STARTCOLLAPSED: 'startCollapsed',

  // Codigos de error en comunicación con la capa de servicios públicos
  NOERROR: '00',
  SERVICE_ERRORCODE_ACTIVE_SESSION: '92',
  SERVICE_ERRORCODE_COMMUNICATION: '999',
  SERVICE_ERRORCODE_EXPIREDSESSION: '86',
  SERVICE_ERRORCODE_INVALID_SESSION: '502',
  SERVICE_ERRORCODE_NOACTION: '122',
  SERVICE_ERRORCODE_NOERROR: '00',
  SERVICE_ERRORCODE_NOSESSION: '148',
  SERVICE_ERRORCODE_PASSWORD_CLOSE_TO_EXPIRE: '113',
  SERVICE_ERRORCODE_PASSWORD_EXPIRED: '109',
  SERVICE_RESOURCE_PERMISSION: '93',
  // Textos de presentación de filtros de busqueda en Crud de lista
  CRUDFILTER_REQUIREDFIELDS: 'Campos requeridos',
  CRUDFILTER_CONTAINS: 'Contiene',
  CRUDFILTER_BETWEEN: 'Entre',
  CRUDLIST_FILENAME: 'Consulta.xlsx',
  FIELD_REQUIRED_MESSAGE: 'Campo requerido',
  SAVE_CONFIRMATION_TITLE: 'Actualizar registro',
  SAVE_CONFIRMATION_BODY: 'Esta seguro que desar actualizar el registro',
  AFTERSAVE_MESSAGE_TITLE: 'Resultado de la actualización del registro',
  GLOBAL_ACTION_ERROR_TITLE: 'Error ejecutando acción',
};

export const tableFieldStyles = {
  TEXT: { 'text-align': 'left' },
  TITLE: { 'text-align': 'left' },
  LABEL: { 'text-align': 'left' },
  MESSAGE: { 'text-align': 'left' },
  NUMBER: { 'text-align': 'right' },
  DATE: { 'text-align': 'right' },
  CHECK: { 'text-align': 'center' },
  ARRAY: { 'text-align': 'left' },
  SELECT: { 'text-align': 'left' },
};
