<div class="formField">
  <div class="ion-item" id="timerangepckrs">
    <div class="ion-item">
      <div
        class="ion-row"
        style="display: grid; grid-template-columns: repeat(2, 1fr); padding-inline: 1rem; padding-block: 0.5rem">
        <div class="ion-col" style="padding-inline: 0.5rem" size="auto">
          <label>Desde:</label>
          <div class="ion-col" class="colValue">
            <nz-time-picker
              style="display: block"
              [nzAllowEmpty]="false"
              [id]="field.code"
              [(ngModel)]="startTime"
              (ngModelChange)="onChangeModel1($event)"
              [nzDisabled]="disabled()"
              nzFormat="hh:mm a"
              [nzMinuteStep]="10"
              [disabled]="disabled()"
              [nzUse12Hours]="true"></nz-time-picker>
          </div>
        </div>
        <div class="ion-col" style="padding-inline: 0.5rem" size="auto">
          <label>Hasta:</label>
          <div class="ion-col" class="colValue">
            <nz-time-picker
              style="display: block"
              [nzAllowEmpty]="false"
              [id]="field.code"
              [(ngModel)]="endTime"
              (ngModelChange)="onChangeModel2($event)"
              [nzDisabled]="disabled()"
              nzFormat="hh:mm a"
              [nzMinuteStep]="10"
              [disabled]="disabled()"
              [nzUse12Hours]="true"></nz-time-picker>
          </div>
        </div>
      </div>
      <p *ngIf="errorMessage()" class="timePickerError">{{ errorMessage() }}</p>
    </div>
  </div>
</div>
