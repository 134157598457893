export const moduleRoutes = {
  root: 'txns',
  childForms: {
    services: {
      data: { title: 'Servicios' },
      requireAuth: true,
      functions: [],
      route: 'services',
    },
    balanceInquiry: {
      data: { title: 'Consulta de Saldos' },
      requireAuth: true,
      functions: [],
      route: 'balanceInquiry',
    },
    withdrawal: {
      data: { title: 'Retiros' },
      requireAuth: true,
      functions: [],
      route: 'withdrawal',
    },
    deposit: {
      data: { title: 'Consignaciones' },
      requireAuth: true,
      functions: [],
      route: 'deposit',
    },
    creditCard: {
      data: { title: 'Tarjeta de crédito' },
      requireAuth: true,
      functions: [],
      route: 'creditCard',
    },
    collectionCategory: {
      data: { title: 'Categorías Recaudos' },
      requireAuth: true,
      functions: [],
      route: 'collectionCategory',
    },
    aguapenCollection: {
      data: { title: 'Recaudo aguapen' },
      requireAuth: true,
      functions: [],
      route: 'aguapenCollection',
    },
    amaguaCollection: {
      data: { title: 'Recaudo amagua' },
      requireAuth: true,
      functions: [],
      route: 'amaguaCollection',
    },
    atmCollection: {
      data: { title: 'Recaudo atm' },
      requireAuth: true,
      functions: [],
      route: 'atmCollection',
    },
    belcorpCollection: {
      data: { title: 'Recaudo belcorp' },
      requireAuth: true,
      functions: [],
      route: 'belcorpCollection',
    },
    claroCollection: {
      data: { title: 'Recaudo claro' },
      requireAuth: true,
      functions: [],
      route: 'claroCollection',
    },
    claroTopUp: {
      data: { title: 'Recarga claro' },
      requireAuth: true,
      functions: [],
      route: 'claroTopUp',
    },
    claroPackageTopUp: {
      data: { title: 'Paquetes claro' },
      requireAuth: true,
      functions: [],
      route: 'claroPackageTopUp',
    },
    tuentiTopUp: {
      data: { title: 'Recarga Tuenti' },
      requireAuth: true,
      functions: [],
      route: 'tuentiTopUp',
    },
    tuentiPackageTopUp: {
      data: { title: 'Paquete Tuenti' },
      requireAuth: true,
      functions: [],
      route: 'tuentiPackageTopUp',
    },
    cntTopUp: {
      data: { title: 'Recarga CNT' },
      requireAuth: true,
      functions: [],
      route: 'cntTopUp',
    },
    cnelCollection: {
      data: { title: 'Recaudo cnel' },
      requireAuth: true,
      functions: [],
      route: 'cnelCollection',
    },
    antCollection: {
      data: { title: 'Recaudo ANT' },
      requireAuth: true,
      functions: [],
      route: 'antCollection',
    },
    cntCollection: {
      data: { title: 'Recaudo cnt' },
      requireAuth: true,
      functions: [],
      route: 'cntCollection',
    },
    cteCollection: {
      data: { title: 'Recaudo cte' },
      requireAuth: true,
      functions: [],
      route: 'cteCollection',
    },
    depratiCollection: {
      data: { title: 'Recaudo deprati' },
      requireAuth: true,
      functions: [],
      route: 'depratiCollection',
    },
    direcTvCollection: {
      data: { title: 'Recaudo directv' },
      requireAuth: true,
      functions: [],
      route: 'direcTvCollection',
    },
    direcTvPosCollection: {
      data: { title: 'Recaudo directv postpago' },
      requireAuth: true,
      functions: [],
      route: 'direcTvPosCollection',
    },
    emaapqCollection: {
      data: { title: 'Recaudo emaapq' },
      requireAuth: true,
      functions: [],
      route: 'emaapqCollection',
    },
    etapaCollection: {
      data: { title: 'Recaudo etapa' },
      requireAuth: true,
      functions: [],
      route: 'etapaCollection',
    },
    jbgCollection: {
      data: { title: 'Junta beneficencia GYE' },
      requireAuth: true,
      functions: [],
      route: 'jbgCollection',
    },
    iessCollection: {
      data: { title: 'Recaudo iess' },
      requireAuth: true,
      functions: [],
      route: 'iessCollection',
    },
    interaguaCollection: {
      data: { title: 'Recaudo interagua' },
      requireAuth: true,
      functions: [],
      route: 'interaguaCollection',
    },
    meerCollection: {
      data: { title: 'Recaudo meer' },
      requireAuth: true,
      functions: [],
      route: 'meerCollection',
    },
    movistarCollection: {
      data: { title: 'Recaudo movistar' },
      requireAuth: true,
      functions: [],
      route: 'movistarCollection',
    },
    movistarPackageTopUp: {
      data: { title: 'Recaudo movistar' },
      requireAuth: true,
      functions: [],
      route: 'movistarPackageTopUp',
    },
    sriCollection: {
      data: { title: 'Recaudo sri' },
      requireAuth: true,
      functions: [],
      route: 'sriCollection',
    },
    tvCableCollection: {
      data: { title: 'Recaudo tvcable' },
      requireAuth: true,
      functions: [],
      route: 'tvCableCollection',
    },
    yanbalCollection: {
      data: { title: 'Recaudo yanbal' },
      requireAuth: true,
      functions: [],
      route: 'yanbalCollection',
    },
    pyccaCollection: {
      data: { title: 'Recaudo pycca' },
      requireAuth: true,
      functions: [],
      route: 'pyccaCollection',
    },
    netlifeCollection: {
      data: { title: 'Recaudo netlife' },
      requireAuth: true,
      functions: [],
      route: 'netlifeCollection',
    },
    ecuanetCollection: {
      data: { title: 'Recaudo ecuanet' },
      requireAuth: true,
      functions: [],
      route: 'ecuanetCollection',
    },
    ecotecCollection: {
      data: { title: 'Recaudo ecotec' },
      requireAuth: true,
      functions: [],
      route: 'ecotecCollection',
    },
    arcsaCollection: {
      data: { title: 'Recaudo arcsa' },
      requireAuth: true,
      functions: [],
      route: 'arcsaCollection',
    },
    ucatolicaCuencaCollection: {
      data: { title: 'Recaudo u católica cuenca' },
      requireAuth: true,
      functions: [],
      route: 'ucatolicaCuencaCollection',
    },
    lagangaCollection: {
      data: { title: 'Recaudo La Ganga' },
      requireAuth: true,
      functions: [],
      route: 'lagangaCollection',
    },
    // Municipios
    dauleCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'dauleCollection',
    },
    guayaquilCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'guayaquilCollection',
    },
    quitoCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'quitoCollection',
    },
    salinasCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'salinasCollection',
    },
    munSamboCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'munSamboCollection',
    },
    santaElenaCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'santaElenaCollection',
    },
    cuencaCollection: {
      data: { title: 'Pago de municipios' },
      requireAuth: true,
      functions: [],
      route: 'cuencaCollection',
    },
    // Recaudos
    teletagTopUp: {
      data: { title: 'Recaudos Teletag' },
      requireAuth: true,
      functions: [],
      route: 'teletagTopUp',
    },
    // Colegios
    schoolPayments: {
      data: { title: 'Pago de colegios' },
      requireAuth: true,
      functions: [],
      route: 'schoolPayments',
    },
    // Pagos
    suppliersPayments: {
      data: { title: 'Recaudo moneyT' },
      requireAuth: true,
      functions: [],
      route: 'suppliersPayments',
    },
    thirdPartyPayments: {
      data: { title: 'Recaudo moneyT' },
      requireAuth: true,
      functions: [],
      route: 'thirdPartyPayments',
    },
    // Giros
    moneyTransferPay: {
      data: { title: 'Recaudo moneyT' },
      requireAuth: true,
      functions: [],
      route: 'moneyTransferPay',
    },
    // Giros locales
    moneyTransferOptions: {
      data: { title: 'Categorías giros locales' },
      requireAuth: true,
      functions: [],
      route: 'moneyTransferOptions',
    },
    payDomesticTransf: {
      data: { title: 'Pago giros locales' },
      requireAuth: true,
      functions: [],
      route: 'payDomesticTransf',
    },
    sendDomesticTransf: {
      data: { title: 'Envío giros locales' },
      requireAuth: true,
      functions: [],
      route: 'sendDomesticTransf',
    },
    codeDomesticTransf: {
      data: { title: 'Reenvío código giros locales' },
      requireAuth: true,
      functions: [],
      route: 'codeDomesticTransf',
    },
    returnDomesticTransf: {
      data: { title: 'Devolución de giros locales' },
      requireAuth: true,
      functions: [],
      route: 'returnDomesticTransf',
    },
    // Transaccción con código
    codedTransaction: {
      data: { title: 'Recaudo moneyT' },
      requireAuth: true,
      functions: [],
      route: 'codedTransaction',
    },
  },
  layout: 'services',
  loadChildren: () => import('src/app/page-modules/transactions/transaction.module').then(m => m.TransactionsModule),
};
