export const environment = {
  production: true,
  authenticated: true,
  hmr: false,
  appConfig: {
    debug: false,
    facePhiRequired: true,
    liveConnection: false,
    serverEntryPoint: {
      protocol: 'https',
      server: 'api.cnb.bolivariano.com',
      port: '',
    },
  },
};
