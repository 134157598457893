import { Component, Injector } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';
import * as moment from 'moment';
import { defaultConfig } from 'src/app/app.config';

const { sessionEstablished } = defaultConfig.appEvents;

moment.locale('es');

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.scss']
})
export class GreetingsComponent extends AppFormComponent {
  storeName: string = '';
  agentBalanceText: string = '';
  agentAccountBalanceDate: string = '';
  currentPath: any[];
  dateString: string;
  userName: string = '';
  date = new Date();
  months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
  isVisible: boolean = true

  constructor(injector: Injector) {
    super(injector);
  }

  override preStart() { this.name = 'welcome'; }
  override start() {
    super.start();
    this.subscribeAppEvents(sessionEstablished.name, (sessionInfo) => {
      this.userName = sessionInfo?.sessionData?.name ?? '';
      this.userName = this.userName.toLowerCase()
      this.userName = this.userName?.split(' ')[0]
    });
    this.dateString = (this.date.toLocaleString('es-ec', { weekday: "long" }) + ' ' + this.date.getDate() + ' de ' + this.months[this.date.getMonth()] + ' ' + this.date.toLocaleString('es-ec', {
      hour: "numeric",
      minute: "numeric"
    }))
  }
}