<div>
  <div class="camLive" *ngIf="!webcamImage">
    <webcam class="ngxWebcam" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
      [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>

    <div class="btnsContainer">
      <button class="actionBtn ant-btn-secondary ant-btn" (click)="showNextWebcam(true);"
        [disabled]="!multipleWebcamsAvailable">Cambiar cámara</button>
      <button class="actionBtn ant-btn-primary ant-btn" (click)="triggerSnapshot();">Capturar</button>
    </div>
  </div>

  <div class="snapshot" *ngIf="webcamImage">
    <img [src]="webcamImage.imageAsDataUrl" alt="webcam image">
    <div class="btnsContainer">
      <button class="actionBtn ant-btn-secondary ant-btn" (click)="remakePicture();">Intentar nuevamente</button>
      <button class="actionBtn ant-btn-primary ant-btn" (click)="confirmCapture();">Confirmar</button>
    </div>
  </div>
</div>