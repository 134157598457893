import { Component, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { countries } from './country';

const PHONE_MIN_LENGTH = 10;
const PHONE_MAX_LENGTH = 10;
@Component({
  selector: 'app-phone',
  templateUrl: './app-phone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPhoneComponent extends FieldComponent {
  phoneCountry: string;
  phoneNumber: string;
  reg = /^(\d*)?$/;
  countries: any = countries;
  defaultCountry: string;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  override focus() { this.inputElement && this.inputElement.nativeElement.focus(); }

  override start() {
    super.start();
    this.defaultCountry = this.formConfig.defaultFieldValues.phone;
    this.field.minLength = PHONE_MIN_LENGTH;
    this.field.maxLength = PHONE_MAX_LENGTH;
    this.field.value || (this.field.value = [this.defaultCountry,]);
    const { country } = this.customAttributes();
    this.countries = country ? countries?.filter(i => i?.code === country) : [];
  }

  flag = code => countries.find(item => item.code === code)?.isoCodes?.toLowerCase();

  filterInput(inputText, option) {
    const { nzLabel, nzValue, nzDisabled, nzHide } = option;
    const nzLabelUpper = nzLabel.toUpperCase();
    const inputUpper = inputText.toUpperCase();
    const country = countries.find(item => item.code === nzValue);
    return (!nzDisabled && !nzHide && (nzLabelUpper.includes(inputUpper)
      || nzValue?.toString()?.includes(inputUpper) || country?.name.toUpperCase().includes(inputUpper)));
  }

  override inputChanged() {
    if (parseInt(this.phoneCountry) !== 57) {
      this.field.maxLength = 12;
      this.field.minLength = 6;
    } else {
      this.field.maxLength = PHONE_MIN_LENGTH;
      this.field.minLength = PHONE_MAX_LENGTH;
    }
    this.value.set([this.phoneCountry ?? this.defaultCountry, this.phoneNumber]);
    super.inputChanged();
  }

  override updateObject() {
    this.value.set([this.phoneCountry ?? this.defaultCountry, this.phoneNumber]);
    this.field.value = [this.phoneCountry ?? this.defaultCountry, this.phoneNumber];
  }

  formatPhoneNumber(phoneData) {
    const [country, phoneNumber] = phoneData;
    const pureNumber = phoneNumber?.toString().replace(/\D+/g, '') ?? '';
    return [country ?? this.defaultCountry, pureNumber];
  }

  override updateValue() {
    this.value.set(this.field.value);
    if (Array.isArray(this.value())) {
      [this.phoneCountry, this.phoneNumber] = this.formatPhoneNumber(this.value());
    } else {
      [this.phoneCountry, this.phoneNumber] = this.formatPhoneNumber([this.defaultCountry, this.value()]);
    }
    this.setValueNativeInput(this.phoneNumber);
  }

  setValueNativeInput(phoneNumber) {
    this.value.set(phoneNumber);
    if (this.inputElement?.nativeElement) {
      this.inputElement.nativeElement.value = phoneNumber ?? '';
    }
  }

  onChangeModel(value: string): void {
    const rawNumber = value?.replace(/\D+/g, '') ?? '';
    if ((!isNaN(+rawNumber) && this.reg.test(rawNumber)) || rawNumber === '') {
      this.phoneNumber = `${rawNumber}`;
    }
    this.setValueNativeInput(this.phoneNumber);
    this.updateObject();
    this.onInputChange();
  }

  override onChangeContent(): void {
    this.updateObject();
    super.onChangeContent();
  }
}
