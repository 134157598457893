<div class="authContainer">
  <div class="containerInner">
    <div class="nav d-flex justify-content-between bd-highlight topbar">
      <div class="float-left nav">
        <img class="header-logo" src="{{ assetsBase }}/images/logos/horizontal-logo-pbb.png" />
      </div>
      <!-- Sección derecha de la barra superios -->
      <div class="bd-highlight sessionMenu">
        <div class="d-flex justify-content-end align-items-center bd-highlight">
          <div class="pe-2 bd-highlight">
            <h6>
              <strong>{{ userName }}</strong>
            </h6>
          </div>
          <div class="pe-2 bd-highlight">
            <app-topbar-user-menu></app-topbar-user-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
