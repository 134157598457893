import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';

import { NZ_I18N, es_ES as localeZorro } from 'ng-zorro-antd/i18n';

import { SharedModule } from './shared/shared.module';
import { reducers, metaReducers } from './store/reducers';
import { IconsProviderModule } from './icons-provider.module';
import { AppInitializerProvider } from './services/app-initializer.service';

import { AppFrontendConfigComponent } from './components/form-based/fontend-config/frontend-config.component';
import { ActivityCheckComponent } from './components/form-based/activity-check/activity-check.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

const PROGRESSBAR_COLOR = '#159aa0';  // OJO: Customization VAR
const LOCALE_PROVIDERS = [
  AppInitializerProvider,
  { provide: NZ_I18N, useValue: localeZorro }
];

registerLocaleData(es); // localeEn, 'en'

@NgModule({
  declarations: [
    AppComponent,
    AppFrontendConfigComponent,
    ActivityCheckComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbModule,
    TranslateModule.forRoot(),
    /**
     * NgRx Store
     */
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),

    /**
     * Nprogress Modules
     */
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: PROGRESSBAR_COLOR,
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    AppRoutingModule,
    HttpClientModule,
    IconsProviderModule,
    FontAwesomeModule,
  ],
  providers: LOCALE_PROVIDERS,
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
