import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as Reducers from 'src/app/store/reducers';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { slideFadeinUp, slideFadeinRight, zoomFadein, fadein } from '../router-animations';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded } = defaultConfig.appEvents;

@Component({
  selector: 'app-layout-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: [
    './welcome.component.scss',
    '../../templates/form.scss',
  ],
  animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein],
})
export class LayoutWelcomeComponent {
  assetsBase: string;
  logo: string;
  isGrayTopbar: boolean;
  isCardShadow: boolean;
  isSquaredBorders: boolean;
  isBorderless: boolean;
  authPagesColor: string;
  routerAnimation: string;
  selectedInitial = 0;
  currentPath: any[]

  constructor(
    private _store: Store<any>,
    private _eventManager: EventManagerService,
    private _appConfig: AppConfigService,
  ) {
    this._eventManager.subscribe(configLoaded.name, () => this.assetsBase = this._appConfig.getParameter('assetsBase'));
    this._store.pipe(select(Reducers.getSettings)).subscribe((state) => {
      this.logo = state.logo;
      this.isGrayTopbar = state.isGrayTopbar;
      this.isCardShadow = state.isCardShadow;
      this.isSquaredBorders = state.isSquaredBorders;
      this.isBorderless = state.isBorderless;
      this.authPagesColor = state.authPagesColor;
      this.routerAnimation = state.routerAnimation;
      this.routerAnimation = state.routerAnimation;
    });
  }

  routeAnimation(outlet: RouterOutlet, animation: string) {
    if (animation === this.routerAnimation) {
      return outlet.isActivated && outlet.activatedRoute.routeConfig.path;
    }
  }
}
