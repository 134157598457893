import { Injectable } from '@angular/core';
import { EventManagerService } from './event-manager.service';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded } = defaultConfig.appEvents;

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  params = {};

  constructor(private _eventManager: EventManagerService) {
    this.loadConfiguration(defaultConfig);
  }

  loadConfiguration(configParams) {
    const parmaNames = Object.keys(configParams);
    parmaNames.forEach((paramName) => {
      this.params[paramName] = configParams[paramName];
    });
    this._eventManager.next(configLoaded.name, this.params);
  }

  updateConfiguration(configParams) {
    this.loadConfiguration(configParams);
    this._eventManager.next(configLoaded.name, this.params);
  }

  getParameter(paramName) {
    return this.params?.[paramName] ?? null;
  }

  setParameter(paramName, value) {
    this.params[paramName] = value;
  }
}
