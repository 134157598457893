import { Component, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { FormHeaderComponent } from 'tuain-ng-forms-lib';
import { Router } from '@angular/router';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded, sessionEnded, sessionEstablished, notifyFormAfterSessionStart } = defaultConfig.appEvents;

@Component({
  selector: 'app-eu-form-branded-header',
  templateUrl: './app-eu-form-branded-header.component.html',
  styleUrls: ['./app-eu-form-branded-header.component.scss'],
})
export class AppEndUserFormBrandedHeaderComponent extends FormHeaderComponent implements OnInit {
  @Input() icon: any;
  @Input() showTitle: boolean = true;
  @Input() title: any;
  @Input() showMenu = false;

  isAuthenticated: boolean = false;
  isRegistered: any;
  name: string = 'Hola';
  intro: string = '¿Qué necesitas realizar hoy?';

  customerName: string = '';
  assetsBase: string;

  @ViewChild('popover') popover;

  optionsOpen: boolean = false;
  path = this.router.url;

  override goBackEvent: EventEmitter<void>;

  constructor(
    public _eventManager: EventManagerService,
    private router: Router,
    public _userSession: UserSessionService,
    private _appConfig: AppConfigService,
  ) {
    super();
  }

  startHeader() {
    if (!this.form) {
      return;
    }
    this.form.subscribeAppEvents(configLoaded.name, () => this.assetsBase = this._appConfig.getParameter('assetsBase'));
    this.form.subscribeAppEvents(sessionEstablished.name, (sessionData) => { sessionData && this.updateViewUponSession(true) });
    this.form.subscribeAppEvents(sessionEnded.name, (sessionData) => { sessionData && this.updateViewUponSession(false) });
  }

  ngOnInit() {
    this.startHeader();
    this.updateViewUponSession(!!this.form._userSession.getSessionData())
  }

  updateViewUponSession(authenticated) {
    this.isAuthenticated = authenticated;
    if (this.isAuthenticated) {
      const sessionData = this._userSession.getSessionData();
      this.name = `Hola ${sessionData?.name ?? ''}, `;
    } else {
      this.name = `Hola, `;
    }
  }

  openRegisterForm() {
    this.form.emitAppEvents(notifyFormAfterSessionStart.name, 'endUserServices');
    this.form.openForm('endUserRegister');
  }

  activateMenu(option) {
    const { form, formData } = option;
    if (form === 'welcome') {
      this.form.showModalDialog('Cierre de sesión', '¿Está seguro que desea cerrar la sesión actual?',
        ['Aceptar', 'Cancelar'], (opt) => {
          if (opt === 0) {
            this.form.emitAppEvents(sessionEnded.name, { endDate: new Date() });
          }
        });
    } else {
      this.form?.openForm(form, formData);
    }
  }

  leave() {
    if (this.isAuthenticated) {
      this.activateMenu({ form: 'welcome' });
    } else {
      this.form?.openForm('endUserAccess');
    }
  }
}