<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <!-- [nzNodes]="" -->
    <nz-tree-select
      [id]="field.code"
      nzShowSearch
      nzCheckable
      [nzNodes]="nodes"
      [nzPlaceHolder]="placeholder()"
      [(ngModel)]="value"
      [nzMultiple]="true"
      [nzAllowClear]="!required() && !disabled()"
      [nzDefaultExpandAll]="true"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      [nzNotFoundContent]="nzEmpty"
      style="width: 100%"
      autocomplete="off"
      [disabled]="disabled()">
    </nz-tree-select>
  </nz-form-control>
</nz-form-item>
<ng-template #nzEmpty>
  <div style="display: flex; justify-content: center">
    <span nz-icon nzType="cloud" nzTheme="twotone" style="font-size: 35px"></span>
  </div>
  <div style="display: flex; justify-content: center">Sin resultados</div>
</ng-template>
