import { Component, ViewChild, ElementRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-alpha-numeric',
  templateUrl: './app-alpha-numeric.component.html',
  styleUrls: ['../input/app-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppAlphaNumericComponent extends FieldComponent {
  @Input() suffixIconName = '';
  valueChanged = false;
  preffix: string = null;
  coversion: string = null;
  separators = true;
  reg = /^[A-Za-z0-9]*$/;
  includeInfo: boolean = false;
  infoContent: any = {};

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  override start() {
    super.start();
    const { suffixIconName = '', showInfoContent = {} } = this.customAttributes();
    this.suffixIconName = suffixIconName;
    this.infoContent = showInfoContent;
    this.includeInfo = Object.keys(showInfoContent).length > 0;
    this.coversion = this.field.getCustomAttribute('coversion') ?? null;
  }

  override focus() {
    this.inputElement && this.inputElement.nativeElement.focus();
  }

  makeReady(): void {
    if (this.valueChanged) {
      this.updateObject();
      this.onChangeContent();
      this.valueChanged = false;
    }
  }

  setValueNativeInput(formatedValue) {
    this.value.set(formatedValue);
    if (this.inputElement?.nativeElement) {
      this.inputElement.nativeElement.value = formatedValue ?? '';
    }
  }

  override updateObject() {
    this.field.value = this.value();
  }

  override updateValue() {
    this.setValueNativeInput(this.field.value);
  }

  onChangeModel(value: string): void {
    this.valueChanged = true;
    let formatedValue = this.value();
    let rawValue = value.toString()?.replace(/[^A-Z\d\s]/gi, '').replace(/\s+/g, '');
    if (this.coversion === 'upperCase') {
      rawValue = rawValue?.toUpperCase();
    } else if (this.coversion === 'lowerCase') {
      rawValue = rawValue?.toLowerCase();
    }
    if (this.reg.test(rawValue) || rawValue === '') {
      formatedValue = `${rawValue}`;
    }
    this.setValueNativeInput(formatedValue);
    this.updateObject();
    this.onInputChange();
  }
}
