import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import store from 'store';
import { parse } from 'qs';
import { NzModalService } from 'ng-zorro-antd/modal';

import { ThemeService } from './services/theme.service';
import * as SettingsActions from 'src/app/store/settings/actions';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { GoogleMapsLoaderService } from './services/google-maps-loader.service';

import { defaultConfig } from 'src/app/app.config';

const {
  appStarted,
  closeInvasiveNotification,
  enableNotifications,
  invasiveNotification,
  keepSessionActive,
  sessionEnded,
  sessionEstablished,
  sessionToBeClosed,
} = defaultConfig.appEvents;

import english from './locales/en-US';
import french from './locales/fr-FR';
import russian from './locales/ru-RU';
import chinese from './locales/zh-CN';

const { warningDuration: WARNING_DURATION } = defaultConfig;

const NOTIFICATION_VIEW_TIME = 300000;
const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  _locale: string;
  _theme: string;
  sessionExpirationTimer: any = null;
  autoCloseTimeout: any = null;
  sessionActive: boolean = false;
  inClosing: boolean = false;
  isCollapsed = false;
  notification: any = {};
  viewNotification: boolean = false;
  notificationEnabled: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private _store: Store<any>,
    private _eventManager: EventManagerService,
    private _userSession: UserSessionService,
    private _googleMapsLoader: GoogleMapsLoaderService,
    private modal: NzModalService,
    translate: TranslateService,
    private themeService: ThemeService
  ) { }

  async ngOnInit() {
    // Cargue del api de Google Maps
    // const loader = new Loader({
    //   apiKey: defaultConfig?.googleApiKey,
    //   version: "weekly",
    // });

    // loader.load().then(async () => {
    //   const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    //   map = new Map(document.getElementById("map") as HTMLElement, {
    //     center: { lat: -34.397, lng: 150.644 },
    //     zoom: 8,
    //   });
    // });

    // set page title from router data variable
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => this.titleService.setTitle(defaultConfig.applicationName + ' | ' + event['title']));

    // listen url query params and set them to ngrx store
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const queryString = event.url.match(/\?(.*)/);
        if (queryString) {
          const queryParams = parse(queryString[1]);
          const keys = Object.keys(queryParams);
          if (keys.length) {
            keys.forEach((key) => {
              let value;
              switch (queryParams[key]) {
                case 'false':
                  value = false;
                  break;
                case 'true':
                  value = true;
                  break;
                default:
                  value = queryParams[key];
                  break;
              }
              this._store.dispatch(
                new SettingsActions.SetStateAction({ [key]: value }),
              );
            });
          }
        }
      });

    // detecting & set mobile/tablet/desktop viewports
    const setViewPort = (isMobileView: any = false, isTabletView: any = false) => {
      this._store.dispatch(
        new SettingsActions.SetStateAction({ isMobileView }),
      );
      this._store.dispatch(
        new SettingsActions.SetStateAction({ isTabletView }),
      );
    };
    const detectViewPort = (load = false) => {
      const _isMobileView = window.innerWidth < 768;
      const _isTabletView = window.innerWidth < 992;
      const _isDesktopView = !_isMobileView && !_isTabletView;
      const isMobileView = JSON.parse(window.localStorage.getItem('app.settings.isMobileView'));
      const isTabletView = JSON.parse(window.localStorage.getItem('app.settings.isTabletView'));
      const isDesktopView = !isMobileView && !isTabletView;
      if (_isDesktopView && (_isDesktopView !== isDesktopView || load)) {
        setViewPort(false, false);
      }
      if (_isTabletView && !_isMobileView && (_isTabletView !== isTabletView || load)) {
        setViewPort(false, true);
        this._store.dispatch(
          new SettingsActions.SetStateAction({ isMenuCollapsed: true }),
        );
      }
      if (_isMobileView && (_isMobileView !== isMobileView || load)) {
        setViewPort(true, false);
      }
    };
    detectViewPort(true);
    window.addEventListener('resize', () => {
      detectViewPort();
    });

    // set primary color on app load
    const primaryColor = () => {
      const color = store.get('app.settings.primaryColor');
      if (color) {
        const addStyles = () => {
          const styleElement = document.querySelector('#primaryColor');
          if (styleElement) {
            styleElement.remove();
          }
          const body = document.querySelector('body');
          const styleEl = document.createElement('style');
          const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`);
          styleEl.setAttribute('id', 'primaryColor');
          styleEl.appendChild(css);
          body.appendChild(styleEl);
        };
        addStyles();
        this._store.dispatch(
          new SettingsActions.SetStateAction({ primaryColor: color }),
        );
      }
    };
    primaryColor();
    await this.startApp();
  }

  setTheme = (theme) => {
    document.querySelector('html').setAttribute('data-kit-theme', theme);
    if (theme === 'default') {
      this._store.dispatch(
        new SettingsActions.SetStateAction({ menuColor: 'gray' }), // 'light'
      );
    }
    if (theme === 'dark') {
      this._store.dispatch(
        new SettingsActions.SetStateAction({ menuColor: 'dark' }),
      );
    }
  };

  toggleTheme(): void {
    this.themeService.toggleTheme().then();
  }

  async startApp() {
    this._eventManager.next(appStarted.name, null);
    this._eventManager.subscribe(sessionEstablished.name, (data) => this.sessionActive = (data?.endUser !== null && data?.endUser !== undefined) ? !(data?.endUser ?? false) : false);
    this._eventManager.subscribe(sessionToBeClosed.name, () => this.showSessionCloseWarning());
    // Subscripciones para control de notificaciones
    this._eventManager.subscribe(sessionEnded.name, (event) => {
      this.notificationEnabled = false;
      this.sessionActive = false;
    });
    this._eventManager.subscribe(enableNotifications.name, (event) => this.notificationEnabled = event?.enable ?? false);
    this._eventManager.subscribe(invasiveNotification.name, (notification) => {
      this.notificationEnabled && this.showInvasiveNotifications(notification);
    });
  }

  showSessionCloseWarning() {
    if (this.inClosing) {
      return;
    }
    this.inClosing = true;
    let modal = this.modal.warning({
      nzTitle: 'Cierre de sesión por inactividad',
      nzContent: 'Su sesión está a punto de expirar, presione OK para mantener abierta la sesión',
      nzOnOk: this.keepSessionOpen.bind(this),
    });
    this.autoCloseTimeout = setTimeout(() => {
      this.inClosing = false;
      modal.destroy();
      modal = this.modal.warning({
        nzTitle: 'El tiempo de la sesión ha concluido',
        nzContent: 'Por tu seguridad, hemos cerrado tu sesión. Por favor, vuelve a ingresar',
      });
      this._eventManager.next(sessionEnded.name, null);
    }, WARNING_DURATION);
  }

  keepSessionOpen() {
    this.inClosing = false;
    if (this.autoCloseTimeout) {
      clearTimeout(this.autoCloseTimeout);
      this.autoCloseTimeout = null;
    }
    this._eventManager.next(keepSessionActive.name, null);
  }

  showInvasiveNotifications(notification) {
    const notificationId = this._userSession.getCustomSessionData('lastInvasiveNotification');
    if (notification?._id === notificationId) {
      return;
    }
    if (notification?._id) {
      if (!this.viewNotification) {
        const { resourceContent, callToAction = {} } = notification?.options ?? {};
        this.notification.id = notification?._id;
        this.notification.type = callToAction?.type;
        this.notification.action = callToAction?.detail;
        this.notification.image = resourceContent;
        this.viewNotification = this.notificationEnabled;
        this._userSession.addCustomSessionData('lastInvasiveNotification', notification?._id ?? null);
        setTimeout(() => this.closeNotification(), NOTIFICATION_VIEW_TIME);
      }
    } else {
      this._userSession.addCustomSessionData('lastInvasiveNotification', null);
    }
  }

  closeNotification() {
    this.viewNotification = false;
    this._eventManager.next(closeInvasiveNotification.name, { id: this.notification.id, permanent: false, nextForm: null });
  }

  openNotification() {
    this.viewNotification = false;
    let nextForm = null;
    if (this.notification.type === 'LINK' && this.notification.action) {
      window.open(this.notification.action, '_external');
    } else if (this.notification.type === 'FORM' && this.notification.action) {
      nextForm = this.notification.action;
    }
    this._eventManager.next(closeInvasiveNotification.name, { id: this.notification.id, permanent: true, nextForm });
  }
}
