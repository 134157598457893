import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded } = defaultConfig.appEvents;

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {
  private apiKey: string = '';
  private scriptLoaded = false;

  constructor(
    protected _appConfig: AppConfigService,
    protected _eventManager: EventManagerService,
  ) {
    this._eventManager.subscribe(configLoaded.name, () => this.initService());
  }

  initService() {
    const googleApiKey = this._appConfig.getParameter('googleApiKey');
    if (googleApiKey) {
      this.apiKey = googleApiKey;
      this.loadGoogleMaps();
    }
  }

  loadGoogleMaps(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.scriptLoaded) {
        resolve(); // Si ya se cargó, no lo vuelvas a cargar
        return;
      }
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.scriptLoaded = true;
        resolve();
      };
      script.onerror = (error) => reject(error);
      document.head.appendChild(script);
    });
  }
}
