import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-tree-select',
  templateUrl: './app-tree-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTreeSelectComponent extends FieldComponent {
  nodes: any[];

  override start() {
    super.start();
    this.buildHierarchy();
  }

  buildHierarchy() {
    const listElements = this.options();
    const rootElements = listElements.filter(item => !item.fieldOptionValue?.parent);
    const childElements = listElements.filter(item => !!item.fieldOptionValue?.parent);
    let allNodes = listElements.map(item => ({
      key: item?.fieldOptionId,
      title: `${item?.fieldOptionValue?.text}`,
      isLeaf: true,
    }));
    allNodes.forEach(item => { item.isLeaf = !listElements.find(listItem => listItem.fieldOptionValue?.parent === item.key); });
    this.nodes = allNodes.filter(item => rootElements.find(rootEl => rootEl.fieldOptionId === item.key));
    childElements.forEach((child) => {
      const childNode: any = allNodes.find(item => item.key === child.fieldOptionId);
      const parentNode: any = allNodes.find(item => item.key === child.fieldOptionValue?.parent);
      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = [childNode];
          parentNode.expanded = true;
          childNode.isLeaf = false;
        } else {
          parentNode.children.push(childNode);
        }
      }
      childNode.isLeaf = !childElements.find(item => item.fieldOptionValue?.parent === child.fieldOptionId);
    });
  }

  filterInput(inputText, option) {
    const upperCaseInput = inputText.toUpperCase();
    const { nzLabel, nzValue, nzDisabled, nzHide } = option;
    return (!nzDisabled && !nzHide && (nzLabel?.toString()?.toUpperCase().includes(upperCaseInput)
      || nzValue?.toString()?.toUpperCase().includes(upperCaseInput)));
  }
}
