import { Component } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded } = defaultConfig.appEvents;

@Component({
  selector: 'notfound',
  templateUrl: '../../../templates/form-specific/auth/notfound.component.html',
})
export class NotFoundComponent {
  assetsBase: string = '';

  constructor(
    private _eventManager: EventManagerService,
    private appConfig: AppConfigService,
  ) {
    this._eventManager.subscribe(configLoaded.name, (data) => { data && (this.assetsBase = this.appConfig.getParameter('assetsBase')); });
  }

  goBack() {
    history.back()
  }
}
