import { Component, ElementRef, ViewChild, Input, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-password',
  templateUrl: './app-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPasswordComponent extends FieldComponent {
  inputType: string = '';
  icon: string = 'eyeslash';

  @Input() prefixIcon = '';
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  override start() {
    super.start();
    this.togglePwdView();
  }

  override focus() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  togglePwdView() {
    this.inputType = (this.inputType === 'password') ? 'text' : 'password';
    this.icon = (this.inputType === 'password') ? 'eyeslash' : 'eye';
  }
}
