<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType() ?? ''" [nzErrorTip]="errorMessage()">
    <nz-input-group [nzAddOnAfter]="" [nzPrefixIcon]="prefixIcon" [nzSuffix]="suffixIconName ? suffixIcon : ''">
      <input
        #inputElement
        nz-input
        [id]="field.code"
        [readOnly]="disabled()"
        [placeholder]="placeholder()"
        [(ngModel)]="value"
        (change)="inputChanged()"
        (keyup)="inputTyped()"
        [maskito]="maskOptions"
        [maskitoElement]="maskPredicate"
        [attr.maxlength]="maxLength()"
        autocomplete="off"
        [disabled]="disabled()" />
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
<ng-template #suffixIcon>
  <app-icon [iconName]="suffixIconName"></app-icon>
</ng-template>