import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LibTableRecordFieldComponent } from 'tuain-ng-forms-lib';
import { formConfig } from 'src/app/components/tuain/forms.module.config';
import * as moment from 'moment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'row-field',
  templateUrl: './row-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTableRecordFieldComponent extends LibTableRecordFieldComponent implements OnInit {
  icon: string = '';
  iconBoolean: string;
  dateFormat: string;
  momentFormat: string;

  override ngOnInit() {
    const checkState = this.fieldValue ? 'check' : 'uncheck'
    this.iconBoolean = (this.fieldType === 'BOOLEAN') ? checkState : '';
    this.dateFormat = formConfig?.defaultFieldAttributes?.tableDateFormat ?? 'YYYY/MM/DD';
    this.momentFormat = formConfig?.defaultFieldAttributes?.tableMomentFormat ?? 'YY-MM-DD HH:mm';
  }

  setStyles(column) {
    const styles = {
      'font-size': column?.customAttributes?.fontSize ?? 'small',
      'font-weight': column?.customAttributes?.fontWeight ?? 'normal',
      'font-family': column?.customAttributes?.fontFamily ?? 'Raleway',
      'margin-top': column?.customAttributes?.marginTop ?? 'unset',
      'margin-bottom': column?.customAttributes?.marginBottom ?? 'unset',
      'color': column?.customAttributes?.color ?? 'unset',
      'border-bottom': column?.customAttributes?.borderBottom ?? 'unset',
      'text-transform': column?.customAttributes?.textTransform ?? 'unset',
      'border-radius': column?.customAttributes?.borderRadius ?? 'unset',
      'position': column?.customAttributes?.position ?? 'unset',
      'top': column?.customAttributes?.top ?? 'unset',
      'right': column?.customAttributes?.right ?? 'unset',
      'bottom': column?.customAttributes?.bottom ?? 'unset',
      'left': column?.customAttributes?.left ?? 'unset',
      'text-align': column?.customAttributes?.textAlign ?? 'unset',
      'line-height': column?.customAttributes?.lineHeight ?? 'unset',
      'margin-left': column?.customAttributes?.marginLeft ?? 'unset',
      'width': column?.customAttributes?.width ?? 'unset'
    }
    return styles;
  }

  parseOnlyDate = date => moment.utc(date).format(this.dateFormat);
  parseMoment = date => moment.utc(date).format(this.momentFormat);
  parseMomentUtc = date => moment(date).format(this.momentFormat);
}
