<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-select
      [id]="field.code"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      nzShowSearch
      [nzAllowClear]="!required()"
      [nzFilterOption]="filterInput"
      [disabled]="disabled()">
      <nz-option
        *ngFor="let option of ciiuList"
        [nzLabel]="option.text"
        [nzValue]="option.value"
        [nzDisabled]="disabled()">
      </nz-option>
    </nz-select>
  </nz-form-control>
</nz-form-item>
