import { Component, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';
import { formatNumber } from '../../utilities';

@Component({
  selector: 'app-currency',
  templateUrl: './app-currency.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCurrencyComponent extends FieldComponent {
  separators = true;
  reg = /^([1-9]\d*)(\.\d{0,2})?$/;
  maxDecimals: number = 2;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  override start() {
    super.start();
    this.configMaxDecimals(this.customAttributes().maxDecimals ?? 2);
  }

  override focus() {
    this.inputElement && this.inputElement.nativeElement.focus();
  }

  override customAttributeChange(subAttribute, value) {
    super.customAttributeChange(subAttribute, value);
    if (subAttribute === 'maxDecimals') {
      this.configMaxDecimals(this.customAttributes().maxDecimals ?? 2);
    }
  }

  configMaxDecimals(maxDecimals): void {
    this.maxDecimals = Math.min(maxDecimals, 2);
    if (this.maxDecimals > 0) {
      this.reg = new RegExp(`^(0(\\.[0-9]{0,${this.maxDecimals}})?|[1-9][0-9]*(\\.[0-9]{0,${this.maxDecimals}})?)$`);
    } else {
      this.reg = /^([1-9]\d*)?$/;
    }

  }

  makeReady(): void {
    if (this.value()?.charAt(this.value().length - 1) === '.' || this.value() === '-') {
      this.value.set(this.value().slice(0, -1));
    }
    this.updateObject();
    this.onChangeContent();
  }

  setValueNativeInput(formatedValue) {
    this.value.set(formatedValue);
    if (this.inputElement?.nativeElement) {
      this.inputElement.nativeElement.value = formatedValue ?? 0;
    }
  }

  override updateObject() {
    const value = this.value().replace(/,/g, '');
    if (value.length > 0) {
      this.field.setValue(isNaN(+value) ? value : +value, false);
    } else {
      this.field.setValue('', false);
    }
  }

  override updateValue() {
    const formatedNumber = this.maxDecimals ? formatNumber(this.field.value, this.maxDecimals) : formatNumber(this.field.value)
    this.setValueNativeInput(formatedNumber);
  }

  onChangeModel(value: string): void {
    let formatedValue = this.value();
    let rawValue = value?.toString()?.replace(/[^0-9,.]+/g, '') ?? '';
    rawValue = rawValue.replace(/,/g, '');
    const suffix = (rawValue?.charAt(rawValue.length - 1) === '.') ? '.' : '';
    const isNumber = !isNaN(+rawValue);
    let step = 1;

    if (isNumber && (rawValue === '' || (this.reg?.test(rawValue)))) {
      const hasSeparator = rawValue.includes('.');
      if (hasSeparator) {
        const raw = rawValue.split('.');
        raw[1] = raw[1].length >= this.maxDecimals ? raw[1].toString().substring(0, 2) : raw[1];
        rawValue = raw.join('.');
      }
      formatedValue = `${formatNumber(rawValue)}${suffix}`;
    }
    this.setValueNativeInput(formatedValue);
    this.updateObject();
    this.onInputChange();
  }
}
