<nz-form-item>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType()" [nzErrorTip]="errorMessage()">
    <nz-date-picker
      nzMode="month"
      [id]="field.code"
      [(ngModel)]="value"
      (ngModelChange)="inputChanged()"
      [nzPlaceHolder]="placeholder()"
      [nzDisabled]="disabled()"
      [nzAllowClear]="!required() && !disabled()"
      nzInputReadOnly
      [disabled]="disabled()">
    </nz-date-picker>
  </nz-form-control>
</nz-form-item>
