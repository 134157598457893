import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconDictionaryService } from 'src/app/services/icon-dictionary.service';

@Component({
  selector: 'app-icon',
  template: `
    <ng-container [ngSwitch]="_iconDictionary.mapIcon(iconName, collection)?.collection">
      <fa-icon *ngSwitchCase="'awesome'" [icon]="_iconDictionary.mapIcon(iconName, collection)?.icon" [size]="size" [style]="style" [styles]="styles"></fa-icon>
      <ion-icon *ngSwitchCase="'ionic'" slot="top" [size]="size" [name]="_iconDictionary.mapIcon(iconName, collection)?.icon"></ion-icon>
      <i *ngSwitchDefault [class]="_iconDictionary.mapIcon(iconName, collection)?.icon"></i>
    </ng-container>
  `,
  styles: ['.itemOption { display: flex; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppIconComponent {
  @Input() iconName: string;
  @Input() collection: string;
  @Input() size: any;
  @Input() shape = '';
  @Input() styles: any;
  @Input() style: string = '';

  constructor(public _iconDictionary: IconDictionaryService) { }
}
