<div *ngIf="globalSearch()">
  <nz-input-group [nzAddOnBefore]="prefixIconSearch">
    <input
      nzType="text"
      nz-input
      [(ngModel)]="globalFilterString"
      (keyup)="globalFilterChanged()"
      (change)="globalFilterCompleted()"
      placeholder="Buscar..."
      autocomplete="off" />
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <app-icon [iconName]="searchIcon"></app-icon>
  </ng-template>
</div>

<div *ngIf="visibleRecords()?.length === 0">
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="customAttributes().emptyMessage"></nz-empty>
</div>

<div class="formTable">
  <ng-container>  
    <nz-list [id]="table.tableCode" class="list">
      <ng-container *ngFor="let record of visibleRecords()">
        <nz-list-item [class]="record?.selected ? 'highLighted' : ''">
          <ng-container *ngIf="avatarField?.fieldCode">
            <div *ngIf="avatarField" class="rowAvatar" (click)="execDefaultAction(record)">
              <img [src]="record.recordData[avatarField.fieldCode]" />
            </div>
          </ng-container>
          <div
            nz-row
            style="width: 100%; flex-wrap: nowrap; align-items: center"
            (click)="execDefaultAction(record)"
            class="avNext">
            <div *ngIf="indicatorField" class="indicator-container">
              <div class="unread-indicator-wrapper">
                <div *ngIf="!record.recordData[indicatorField.fieldCode]" class="unread-indicator"></div>
              </div>
            </div>
            <div nz-col nzFlex="auto" style="text-align: left">
              <ng-container *ngIf="leftFields?.length > 0" class="leftCol">
                <div
                  *ngFor="let column of leftFields"
                  class="tableRow ion-justify-content-start"
                  [ngStyle]="leftStyle ?? ''">
                  <row-field
                    [column]="column"
                    [fieldCode]="column.fieldCode"
                    [fieldType]="column.fieldType"
                    [fieldValue]="record.recordData[column.fieldCode]"
                    [class]="column.fieldCode">
                  </row-field>
                </div>
              </ng-container>
            </div>
            <div nz-col style="text-align: right">
              <ng-container *ngIf="rightFields?.length > 0" size="auto">
                <div *ngFor="let column of rightFields" class="ion-justify-content-end" [ngStyle]="rightStyle ?? ''">
                  <row-field
                    [column]="column"
                    [fieldCode]="column.fieldCode"
                    [fieldType]="column.fieldType"
                    [fieldValue]="record.recordData[column.fieldCode]"
                    [class]="column.fieldCode">
                  </row-field>
                </div>
              </ng-container>
            </div>
          </div>
          <actions-dropdown
            *ngIf="inlineActions.length > 1"
            [defaultAction]="defaultAction"
            [actions]="inlineActions"
            [record]="record"
            (actionSelected)="inlineActionSelected($event)"></actions-dropdown>
        </nz-list-item>
      </ng-container>
    </nz-list>
  </ng-container>
</div>
