import { Component } from '@angular/core';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { AppConfigService } from 'src/app/services/app-configuration.service';
import { defaultConfig } from 'src/app/app.config';

const { configLoaded } = defaultConfig.appEvents;

@Component({
  selector: 'app-layout-auth',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss'],
})
export class LayoutOnboardComponent {
  assetsBase: string;
  logo: string;
  isGrayTopbar: boolean;
  isCardShadow: boolean;
  isSquaredBorders: boolean;
  isBorderless: boolean;
  authPagesColor: string;
  routerAnimation: string;
  selectedInitial = 0;

  constructor(
    // private _store: Store<any>,
    private _eventManager: EventManagerService,
    private appConfig: AppConfigService,
  ) {
    this._eventManager.subscribe(configLoaded.name, (data) => { data && (this.assetsBase = this.appConfig.getParameter('assetsBase')); });
  }
}
