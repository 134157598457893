import { Component, Input } from '@angular/core';
import { FormHeaderComponent } from 'tuain-ng-forms-lib';
import { EventManagerService } from 'src/app/services/event-manager.service';
import { Router } from '@angular/router';
import { defaultConfig } from 'src/app/app.config';

const { sessionEnded } = defaultConfig.appEvents;

@Component({
  selector: 'app-form-header',
  templateUrl: './app-form-header.component.html',
  styleUrls: ['./app-form-header.component.less'],
})
export class AppFormHeaderComponent extends FormHeaderComponent {
  @Input() icon: any;
  @Input() showTitle: boolean = true;
  @Input() title: any;
  @Input() showMenu = false;
  @Input() badgeCounter: number = 0;
  showMenuTimer: any = null;
  statusBarHeight: string = '2rem';

  optionsOpen: boolean = false;
  path = this.router.url

  constructor(
    private router: Router,
    private _eventManager: EventManagerService,
  ) {
    super();
    this._eventManager.subscribe(sessionEnded.name, (sessionData) => { sessionData && (this.optionsOpen = false); });
  }

  execAction(action) {
    if (this.showMenuTimer) {
      clearTimeout(this.showMenuTimer);
    }
    this.optionsOpen = false;
    setTimeout(() => action?.notifyActivation(), 100);
  }
}
