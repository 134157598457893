import { Injectable } from '@angular/core';
import { DeviceManagementService } from './device-management.service';
import { StorageService } from './storage.service';
import { EventManagerService } from './event-manager.service';
import { APIGatewayService } from './api-gateway.service';
import { ComponentPageService } from './component-pages.service';
import { defaultConfig } from 'src/app/app.config';

const {
  formActivity,
  keepSessionActive,
  menuObtained,
  noSessionNext,
  sessionEncryptKeyReceived,
  sessionEnded,
  sessionEstablished,
  sessionObtained,
  sessionSignKeyReceived,
  sessionToBeClosed,
} = defaultConfig.appEvents;

const { sessionActivityNotify, sessionToBeClosedWarning } = defaultConfig;

const SESSION_ID = 'sessionId';
const RECOVERY_GAP_SERVER = 30000;

export const activeSessionErrors = Object.freeze({
  validSession: 0,
  expiredOnServer: 1,
  concurrentSession: 2,
  missingUserFunctions: 3,
  noSession: 4,
});

@Injectable({ providedIn: 'root' })
export class UserSessionService {
  sessionData: any = null;
  appMenus: any;
  userOptions: any;
  lastRequestedForm: any = '';
  lastAppActivityDate: Date = null;
  lastSessionRecovery: Date = null;
  sessionExpirationDate: Date = null;
  sessionEndTimer: any;
  sessionKeepAliveTimer: any;
  customSessionData: any = {};

  constructor(
    private _eventManager: EventManagerService,
    private _apiGateway: APIGatewayService,
    private _storageService: StorageService,
    private _componentPageService: ComponentPageService,
    private _deviceManagementService: DeviceManagementService,
  ) {
    this.startSessionService();
  }

  startSessionService() {
    this.resetCustomSessionData();
    this._eventManager.subscribe(sessionObtained.name, data => this.completeSessionEstablish(data));
    this._eventManager.subscribe(sessionEnded.name, data => this.clearService(data?.nextForm));
    this._eventManager.subscribe(noSessionNext.name, route => this.setRequestedRoute(route));
    this._eventManager.subscribe(formActivity.name, () => this.keepSessionActive());
    this._eventManager.subscribe(keepSessionActive.name, () => this.keepSessionActive());
  }

  resetCustomSessionData() {
    this.customSessionData = {};
  }

  addCustomSessionData(name, value) {
    this.customSessionData[name] = value;
  }

  delCustomSessionData(name) {
    delete this.customSessionData?.[name];
  }

  getCustomSessionData(name) {
    return this.customSessionData?.[name] ?? null;
  }

  setSessionExpirationDate(expireOn) {
    this.sessionExpirationDate = expireOn ? new Date(expireOn) : new Date();
    const currentDate = new Date();
    const remainingSessionTime = this.sessionExpirationDate.getTime() - currentDate.getTime();
    this.sessionEndTimer && clearTimeout(this.sessionEndTimer);
    this.sessionEndTimer = setTimeout(() => this._eventManager.next(sessionToBeClosed.name, null), remainingSessionTime - sessionToBeClosedWarning);
  }

  clearService(nextForm = null) {
    this.sessionKeepAliveTimer && clearTimeout(this.sessionKeepAliveTimer);
    this.sessionEndTimer && clearTimeout(this.sessionEndTimer);
    this.sessionData = null;
    this.appMenus = null;
    this.userOptions = null;
    this.sessionExpirationDate = null;
    this.sessionKeepAliveTimer = null;
    this.clearStoredSessionId();
    if (nextForm && !this.lastRequestedForm) {
      this.lastRequestedForm = nextForm;
    }
  }

  setRequestedRoute(nextRoute) {
    if (nextRoute) {
      const [subject] = nextRoute.split('/').splice(-1);
      this.lastRequestedForm = [this._componentPageService.routeForm(nextRoute), subject];
    }
  }

  async keepSessionActive() {
    if (!this.sessionData) {
      return;
    }
    this.lastAppActivityDate = new Date();
    const remainingSessionTime = this.sessionExpirationDate.getTime() - this.lastAppActivityDate.getTime();
    this.sessionKeepAliveTimer && clearTimeout(this.sessionKeepAliveTimer);
    this.sessionKeepAliveTimer = setTimeout(() => this.keepSessionAlive(), remainingSessionTime - sessionToBeClosedWarning - sessionActivityNotify);
  }

  async keepSessionAlive() {
    console.log(`*** Solicitud server keepSessionAlive\n   expire: ${this.sessionExpirationDate}\n   lastAcct: ${this.lastAppActivityDate}\n`);
    const { data: keepSessionResult } = await this._apiGateway.keepSessionAlive(this.lastAppActivityDate);
    const { expireOn } = keepSessionResult;
    this.setSessionExpirationDate(expireOn);
  }

  async activeSession() {
    const currentSessionInApp = (this.sessionData && this.userOptions?.userFunctions)
      ? this.sessionData?.sessionId : null;
    let activeSessionResult: any = (currentSessionInApp) ? activeSessionErrors.validSession : activeSessionErrors.noSession;
    let timeGap = RECOVERY_GAP_SERVER;
    if (this.lastSessionRecovery) {
      const currentDate = new Date();
      timeGap = currentDate.getTime() - this.lastSessionRecovery.getTime();
    }
    if (timeGap < RECOVERY_GAP_SERVER && currentSessionInApp) {
      this.keepSessionActive();
    } else {
      this.lastSessionRecovery = new Date();
      const { data: serverSessionData } = await this._apiGateway.obtainCurrentSession();
      const { sessionId: currentSessionInServer, expireOn } = serverSessionData;
      this.setSessionExpirationDate(expireOn);
      this.storeNewSessionId(currentSessionInServer);
      if (!currentSessionInApp) {
        if (!currentSessionInServer) {
          this.clearStoredSessionId();
          activeSessionResult = activeSessionErrors.noSession;
        } else {
          this.resetCustomSessionData();
          activeSessionResult = await this.completeSessionEstablish(serverSessionData, !!currentSessionInApp, false);
        }
      } else {
        if (!currentSessionInServer) {
          this.clearStoredSessionId();
          activeSessionResult = activeSessionErrors.expiredOnServer;
        } else if (currentSessionInServer !== currentSessionInApp) {
          activeSessionResult = activeSessionErrors.concurrentSession;
        }
      }
    }
    return activeSessionResult;
  }

  async completeSessionEstablish(sessionData, currentlyOnSession = false, changePage = true) {
    if (!sessionData) {
      return activeSessionErrors.noSession;
    }
    const { expireOn } = sessionData;
    this.setSessionExpirationDate(expireOn);
    this.lastSessionRecovery = new Date();
    const signCryptogram = sessionData?.sessionKeys?.signKeyCrypto ?? null;
    const encryptKeyCryptogram = sessionData?.sessionKeys?.encryptKeyCrypto ?? null;
    if (signCryptogram && encryptKeyCryptogram) {
      const signKey = await this._deviceManagementService.decryptData(signCryptogram);
      const encryptKey = await this._deviceManagementService.decryptData(encryptKeyCryptogram);
      this._eventManager.next(sessionSignKeyReceived.name, signKey);
      this._eventManager.next(sessionEncryptKeyReceived.name, encryptKey);
    }
    this.storeNewSessionId(sessionData.sessionId);
    this.sessionData = sessionData;
    if (!currentlyOnSession) {
      const userOptionsResp = await this._apiGateway.getUserPrivileges();
      const frontendMenusRes = await this._apiGateway.getMenuData();
      const frontendMenus = frontendMenusRes.getData();
      this.userOptions = userOptionsResp.getData();
      if (!this.userOptions) {
        return activeSessionErrors.missingUserFunctions;
      }
      const userFunctions = this.userOptions?.userFunctions ?? [];
      const fullSessionData = {
        changePage,
        sessionData,
        privileges: userFunctions,
        nextForm: this.lastRequestedForm,
      };
      this.storeNewSessionId(sessionData?.sessionId);
      this._eventManager.next(sessionEstablished.name, fullSessionData);
      // Se personalizan  los menus obtenidos
      this.appMenus = {};
      for (let index = 0; index < frontendMenus.length; index++) {
        const { name, items } = frontendMenus[index];
        this.appMenus[name] = this.filterMenuItems(items, userFunctions);
      }
      this._eventManager.next(menuObtained.name, this.appMenus);
      this.lastRequestedForm = '';
    }
    return activeSessionErrors.validSession;
  }

  async buildFakeSession(sessionData) {
    this.sessionData = sessionData;
    const fakeSessionExpiration = new Date();
    fakeSessionExpiration.setDate(fakeSessionExpiration.getDate() + 1);
    this.setSessionExpirationDate(fakeSessionExpiration);
    this.lastSessionRecovery = new Date();
    this.storeNewSessionId(sessionData.sessionId);
  }

  getAppMenus() {
    return this.appMenus;
  }

  userCanNavigate(fullRequestedRoute, requiredFunctions = []) {
    const funRequired = [...(this._componentPageService.pathFunctions(fullRequestedRoute) ?? [])];
    funRequired.push(...requiredFunctions);
    if (!funRequired || funRequired.length === 0) {
      return true;
    }
    const userFunctions = this.userOptions?.userFunctions;
    const hasAllFunctions = userFunctions
      ? funRequired.reduce((hasAll, name) => hasAll && userFunctions.includes(name), true)
      : false;
    return hasAllFunctions;
  }

  filterMenuItems(menuObject, userFunctions) {
    const outputMenu = menuObject.filter(item =>
      this.allFunctionsIncluded(item.functions, userFunctions),
    );
    for (let index = 0; index < outputMenu.length; index++) {
      const menuItem = outputMenu[index];
      if (menuItem.children) {
        menuItem.children = this.filterMenuItems(menuItem.children, userFunctions);
      }
    }
    return outputMenu;
  }

  allFunctionsIncluded(functions, userFunctions) {
    if (functions?.length > 0 && userFunctions?.length > 0) {
      for (let index = 0; index < functions.length; index++) {
        if (!userFunctions.includes(functions[index])) {
          return false;
        }
      }
    }
    return true;
  }

  storeNewSessionId(sessionId) {
    return sessionId && this._storageService.setItem(SESSION_ID, { sessionId });
  }

  clearStoredSessionId() {
    return this._storageService.removeItem(SESSION_ID);
  }

  getLastRequestedRoute() { return this.lastRequestedForm; }
  getUserData() { return { sessionData: this.sessionData, userOptions: this.userOptions }; }
  getSessionData() { return this.sessionData; }
}
