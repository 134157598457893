import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-tree-array',
  templateUrl: './app-tree-array.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTreeArrayComponent extends FieldComponent {
  nodes: any[];
  allNodes: any[];

  override start() {
    super.start();
    this.buildHierarchy();
  }

  override inputChanged() {
    let selectedOptions = this.value();
    let pendingProcess = true;
    while (pendingProcess) {
      const optionsChildren = this.options()?.filter(item => selectedOptions.includes(item.fieldOptionValue.parent))
        .map(item => item.fieldOptionId) ?? [];
      const currentSize = selectedOptions.length;
      selectedOptions = [...new Set([...selectedOptions, ...optionsChildren])];
      pendingProcess = (selectedOptions.length > currentSize);
    }
    const leafNodes = this.allNodes.filter(item => selectedOptions.includes(item.key)
      && item.isLeaf).map(item => item.key);
    this.value.set(leafNodes);
    super.inputChanged();
  }

  override updateValue() {
    super.updateValue()
    this.buildHierarchy();
  }

  buildHierarchy() {
    const listElements = this.options();
    const rootElements = listElements.filter(item => !item.fieldOptionValue?.parent);
    const childElements = listElements.filter(item => !!item.fieldOptionValue?.parent);
    this.allNodes = listElements.map(item => ({
      key: item?.fieldOptionId,
      title: `${item?.fieldOptionValue?.text}`,
      isLeaf: true,
    }));
    this.allNodes.forEach(item => { item.isLeaf = !listElements.find(listItem => listItem.fieldOptionValue?.parent === item.key); });
    this.nodes = this.allNodes.filter(item => rootElements.find(rootEl => rootEl.fieldOptionId === item.key));
    childElements.forEach((child) => {
      const childNode: any = this.allNodes.find(item => item.key === child.fieldOptionId);
      const parentNode: any = this.allNodes.find(item => item.key === child.fieldOptionValue?.parent);
      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = [childNode];
          parentNode.expanded = true;
          childNode.isLeaf = false;
        } else {
          parentNode.children.push(childNode);
        }
      }
      childNode.isLeaf = !childElements.find(item => item.fieldOptionValue?.parent === child.fieldOptionId);
    });
  }

  filterInput(inputText, option) {
    const upperCaseInput = inputText.toUpperCase();
    const { nzLabel, nzValue, nzDisabled, nzHide } = option;
    return (!nzDisabled && !nzHide && (nzLabel?.toString()?.toUpperCase().includes(upperCaseInput)
      || nzValue?.toString()?.toUpperCase().includes(upperCaseInput)));
  }
}
