import CryptoJS from 'crypto-js';
const cedulaCodes = ['CC', 'CI', 'CED', 'IDENTIFICACION'];
const rucCodes = ['RUC'];
const MAX_PROVINCE = 24;
const RUC_PERSONA = ['0', '1', '2', '3', '4', '5', '6'];
const RUC_JURIDICO = ['9'];
const RUC_PUBLICO = ['6'];

export function ecuadorIdentificationValidation(idType, idNumber) {
  if (!idType || !idNumber || (!rucCodes.includes(idType) && !cedulaCodes.includes(idType))) {
    return null;
  }
  let message = (rucCodes.includes(idType)) ? 'El campo no es un RUC válido, '
    : 'El campo no corresponde una cédula válida, ';
  const expectedLength = (rucCodes.includes(idType)) ? 13 : 10;
  let verificationDigit;

  if (idNumber.length !== expectedLength) {
    return message + 'longitud incorrecta';
  }
  const province = +idNumber.substr(0, 2);
  if (province < 1 || province > MAX_PROVINCE) {
    return message + 'provincia inválida';
  }
  if (rucCodes.includes(idType)) {
    const personType = idNumber.substr(2, 1);
    let branchNumber;
    let coheficients;
    if (RUC_JURIDICO.includes(personType)) {
      branchNumber = idNumber.substr(10, 3);
      verificationDigit = idNumber.substr(9, 1);
      coheficients = [4, 3, 2, 7, 6, 5, 4, 3, 2];
    } else if (RUC_PUBLICO.includes(personType)) {
      branchNumber = idNumber.substr(10, 3);
      verificationDigit = idNumber.substr(8, 1);
      coheficients = [3, 2, 7, 6, 5, 4, 3, 2];
    } else if (RUC_PERSONA.includes(personType)) {
      branchNumber = idNumber.substr(10, 3);
      idType = 'CC';
      idNumber = idNumber.substr(0, 10);
    } else {
      return message + 'tipo de persona inválida';
    }
    if (+branchNumber !== 1) {
      return message + 'sucursal inválida';
    }
    if (RUC_JURIDICO.includes(personType) || RUC_PUBLICO.includes(personType)) {
      let digitsSum = 0;
      for (let index = 0; index < coheficients.length; index++) {
        const digit = idNumber[index];
        digitsSum += (+digit) * coheficients[index];
      }
      digitsSum %= 11;
      if (digitsSum !== 0) {
        digitsSum = 11 - digitsSum;
      }
      if (+verificationDigit !== digitsSum) {
        return message + 'digito de verificación no coincide';
      }
    }
  }
  if (!idType || cedulaCodes.includes(idType)) {
    verificationDigit = idNumber.substr(9, 1);
    const coheficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    let digitsSum = 0;
    for (let index = 0; index < 9; index++) {
      const digit = idNumber[index];
      let digitsProduct = (+digit) * coheficients[index];
      digitsProduct = (digitsProduct > 9) ? digitsProduct - 9 : digitsProduct;
      digitsSum += digitsProduct;
    }
    digitsSum %= 10;
    if (digitsSum !== 0) {
      digitsSum = 10 - digitsSum;
    }
    if (+verificationDigit !== digitsSum) {
      return message + 'digito de verificación no coincide';
    }
  }
  return null;
}

export function generateStringHash(rootValue) {
  const value = rootValue.trim();
  const hash = CryptoJS.SHA256(value)?.toString(CryptoJS.enc.Base64);
  return hash;
}
