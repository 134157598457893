import { Component, Input } from '@angular/core';
import { LibTableRecordActionComponent } from 'tuain-ng-forms-lib';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'inline-action',
  templateUrl: './inline-action.component.html',
  styleUrls: ['./inline-action.component.scss'],
})
export class AppTableInlineActionComponent extends LibTableRecordActionComponent {
  icon: string;
  confirm: string = null;
  @Input() size = 'default';
  @Input() type = 'default';
  isActive: boolean;

  constructor() {
    super();
  }
  override start() {
    super.start();
    this.confirm = this.action.getCustomAttribute('preConfirm') ?? null;
  }
}