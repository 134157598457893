import { Component } from '@angular/core';
import { AppFormComponent } from 'src/app/components/tuain/forms/app-form.component';
import { formatNumber } from 'src/app/utils/general-purpose';
import { defaultConfig } from 'src/app/app.config';

const {
  agentStatus,
  balanceUpdated,
  chgTxnActiveGroup,
  menuObtained,
  sessionEnded,
  storeServices,
  txnGroupSelection,
  updateAgentBalance,
  pointedByApp,
} = defaultConfig.appEvents;

const canBePointedByApp = ['balanceInquiry'];

const subMenuName = 'serviceMenu';
const frm = {
  name: 'agentBalance',
  states: { default: 'default' },
  sections: { default: 'default' },
  subsections: { default: 'default' },
  fields: {
    agentAccountBalance: 'agentAccountBalance',
    agentAccountBalanceDate: 'agentAccountBalanceDate',
    agentAccountActive: 'agentAccountActive',
  },
  actions: {
    reloadBalance: 'reloadBalance',
  },
  tables: {},
};

const READ_CODES = 'Lectura de códigos';
const SESSION_ENDED = 'sessionEnded';

@Component({
  selector: 'app-txn-menu',
  templateUrl: './txn-menu.component.html',
  styleUrls: [
    './txn-menu.component.scss',
    '../../../templates/form.scss',
  ],
})
export class TxnMenucomponent extends AppFormComponent {
  firstGroup: any;
  segondGroup: any[];
  services: any[];
  storeName: string = '';
  agentBalance: number = 0;
  transactionalFunction: string = '';
  transactionalUser: boolean = true;
  validTime: boolean = true;
  validPosition: boolean = true;
  agentTotalBalance: number = 0;
  balanceUse: number = 0;
  minAgentBalance: number = 0;
  agentBalanceText: string = '...';
  formMessages: any = {};
  agentTotalBalanceText: string = '...';
  agentAccountBalanceDate: string = '';
  agentAccountActive: boolean = true;
  agentOutOfRange: boolean = false;
  refreshEventTarget: any = null;
  hasToolbar: boolean = true;
  currentSelection: number = 0;
  selectedItemMenu: any = null;
  codesGroup: any = [];
  preparedPointers: any = {};

  override preStart() {
    this.name = frm.name;
  }

  override start() {
    super.start();
    this.selectedItemMenu = null;
    this.refreshWithPreviousData();
    this.disableActivityNotification();
    this.transactionalFunction = this.getExtraInfo('transactionalFunction');
    this.minAgentBalance = this.getExtraInfo('minAgentBalance');
    this.formMessages.balanceUnderLimit = this.getExtraInfo('balanceUnderLimit');
    this.formMessages.agentOutOfRange = this.getExtraInfo('agentOutOfRange');
    this.formMessages.inactiveCNBAccount = this.getExtraInfo('inactiveCNBAccount');
    this.subscribeAppEvents(pointedByApp.name, pointer => this.updateSelector(pointer));
    this.subscribeAppEvents(chgTxnActiveGroup.name, selection => {
      if (!selection) { return; }
      this.currentSelection = selection?.index;
      this.transactionGroupChanged(selection?.index);
    });
    this.onActionStart(frm.actions.reloadBalance, async () => {
      const geolocation = this._userSession.getCustomSessionData('geolocation');
      this.setFieldValue('geolocation', geolocation);
    });
    this.onActionFinish(frm.actions.reloadBalance, () => {
      this.refreshEventTarget?.complete();
      this.refreshEventTarget = null;
      this.recoverAgentBalance();
    });
    // Se inicializa previo a las subscripciones para que se dispare el evento
    this.initOperation();
    this.subscribeAppEvents(menuObtained.name, menuData => this.buildMenu(menuData));
    const readCodes = JSON.parse(JSON.stringify(this.services?.find(srv => srv.title === READ_CODES)))
    this.codesGroup.push(readCodes)
    this.subscribeAppEvents(agentStatus.name, (agentStatus) => {
      agentStatus && this.updateAgentStatus(agentStatus);
    });
    this.subscribeAppEvents(updateAgentBalance.name, () => this.startAction(frm.actions.reloadBalance));
  }

  initOperation() {
    this.startAction(frm.actions.reloadBalance);
  }

  refreshWithPreviousData() {
    const agentBalanceAndState = this._userSession?.getCustomSessionData('agentBalanceAndState') ?? null;
    if (!agentBalanceAndState) { return; }
    const {
      agentAccountActive, agentBalance, agentBalanceText, agentTotalBalance, agentTotalBalanceText, agentAccountBalanceDate,
      validTime, validPosition
    } = agentBalanceAndState;
    this.agentAccountBalanceDate = agentAccountBalanceDate;
    this.validTime = validTime;
    this.validPosition = validPosition;
    this.agentBalance = agentBalance;
    this.agentBalanceText = agentBalanceText;
    this.agentTotalBalance = agentTotalBalance;
    this.agentTotalBalanceText = agentTotalBalanceText;
    this.balanceUse = this.agentBalance / this.agentTotalBalance;
    this.agentAccountActive = agentAccountActive ?? true;
  }

  recoverAgentBalance() {
    const sessionLost = this.getExtraInfo('sessionLost');
    if (sessionLost === true) {
      this.emitAppEvents(sessionEnded.name, { endDate: new Date() });
    }
    // Se recupera la información de saldo y fecha del saldo
    const agentAccountBalance = this.getFieldValue(frm.fields.agentAccountBalance);
    this.agentAccountBalanceDate = this.getFieldValue(frm.fields.agentAccountBalanceDate);
    const agentAccountActive = this.getFieldValue(frm.fields.agentAccountActive);

    this.agentAccountActive = agentAccountActive ?? true;
    const agentBalance = Number.isNaN(+agentAccountBalance) ? 0 : +agentAccountBalance;
    this.agentBalance = agentBalance;
    this.agentTotalBalance = agentBalance;
    this.balanceUse = this.agentBalance / this.agentTotalBalance;
    this.agentBalanceText = formatNumber(agentBalance);
    this.agentTotalBalanceText = formatNumber(this.agentTotalBalance);
    this.refreshAgentInfo();
  }

  refreshAgentInfo() {
    const prevAgentOutOfRange = this._userSession.getCustomSessionData('agentOutOfRange') ?? null;
    if (this.agentOutOfRange && prevAgentOutOfRange !== this.agentOutOfRange) {
      this.showNotifyPopup('Corresponsal fuera de area', this.formMessages.agentOutOfRange);
    }
    if (prevAgentOutOfRange !== this.agentOutOfRange) {
      this._userSession.addCustomSessionData('agentOutOfRange', this.agentOutOfRange);
    }
    const prevAgentAccountActive = this._userSession.getCustomSessionData('agentAccountStatus');
    if (!this.agentAccountActive && prevAgentAccountActive !== this.agentAccountActive) {
      this.showNotifyPopup('Corresponsal Inactivo', this.formMessages.inactiveCNBAccount);
    }
    if (prevAgentAccountActive !== this.agentAccountActive) {
      this._userSession.addCustomSessionData('agentAccountStatus', this.agentAccountActive);
    }
    this._userSession.addCustomSessionData('lastBalanceUpdated', this.agentTotalBalance);
    if (this.agentAccountActive && this.agentTotalBalance && this.agentTotalBalance <= this.minAgentBalance) {
      const previousNotify = this._userSession.getCustomSessionData('insufficientBalanceNotify');
      if (!previousNotify) {
        this.showNotifyPopup('Saldo disponible bajo', this.formMessages.balanceUnderLimit);
        this._userSession.addCustomSessionData('insufficientBalanceNotify', true);
      }
    }
    const agentBalanceAndState = {
      agentAccountActive: this.agentAccountActive,
      agentBalance: this.agentBalance,
      agentBalanceText: this.agentBalanceText,
      agentTotalBalance: this.agentTotalBalance,
      agentTotalBalanceText: this.agentTotalBalanceText,
      agentAccountBalanceDate: this.agentAccountBalanceDate,
      validTime: this.validTime,
      validPosition: this.validPosition,
    };
    this.emitAppEvents(balanceUpdated.name, {
      storeName: this.storeName,
      agentBalanceText: this.agentBalanceText,
      agentAccountBalanceDate: this.agentAccountBalanceDate,
      agentAccountActive: this.agentAccountActive,
    });
    this._userSession.addCustomSessionData('agentBalanceAndState', agentBalanceAndState);
  }

  buildMenu(inputData) {
    this._userSession.addCustomSessionData('servicesMenuData', inputData);
    const data = JSON.stringify(inputData?.[subMenuName], null, 2);
    const menuData = JSON.parse(data);
    this.services = this.preprocessMenudata(menuData);
    const userSession = this._userSession.getSessionData();
    this.storeName = userSession?.storeName;
    this.emitAppEvents(storeServices.name, { services: this.services });
  }

  preprocessMenudata(menuData) {
    const userFunctions = this._userSession.getUserData()?.userOptions?.userFunctions ?? [];
    // Se determina si el usuario es transaccional
    this.transactionalUser = this.transactionalFunction && userFunctions?.includes(this.transactionalFunction);
    menuData?.forEach((item) => {
      item.iconName = item.icon;
      item.key = item.form;
      if (item.additionalInfo && !Array.isArray(item.additionalInfo)) {
        item.additionalInfo = [item.additionalInfo];
      }
      if (item.form) {
        let pageUrl = `/${this._componentPageService.formRoute(item.form)}`;
        item.additionalInfo = (item.additionalInfo && !Array.isArray(item.additionalInfo))
          ? [item.additionalInfo] : item.additionalInfo;
        item.url = pageUrl;
        item.enabled = (item.enableFunctions?.some((item) => userFunctions?.includes(item)) ?? true);
      }
      if (item.children) {
        this.preprocessMenudata(item.children);
      }
      if (canBePointedByApp.includes(item.key)) {
        this.preparedPointers[item.key] = item
      }
    });
    return menuData;
  }

  openItemForm(service) {
    const { form, params, enabled } = service;
    if (!this.agentBalance || !this.agentAccountActive || !this.validTime || !this.validPosition) {
      return;
    }
    if (!this.transactionalUser || !enabled) {
      return;
    }
    this.selectedItemMenu = service;
    if (params) {
      const fields = {};
      params?.forEach(param => Object.assign(fields, param));
      this.openForm(form, { fields });
    } else {
      this.openForm(form);
    }
  }

  updateSelector(pointer) {
    this.selectedItemMenu = this.preparedPointers[pointer]
  }

  refreshAgentBalance(event) {
    this.refreshEventTarget = event.target;
    this.startAction(frm.actions.reloadBalance);
  }

  transactionGroupChanged(index) {
    const svcGroup = this.services[index]
    const isLast = this.codesGroup[0]?.title === svcGroup?.title
    if (isLast) {
      this.services[index].children = null
      this.openItemForm(this.codesGroup[0]?.children[0])
    }
    this.emitAppEvents(txnGroupSelection.name, { index });
  }

  updateAgentStatus(agentStatus) {
    const {
      validPosition, agentAccountBalanceDate, validTime,
      agentBalance, agentTotalBalance, activeAccount,
    } = agentStatus;
    this.validTime = validTime ?? this.validTime;
    if (validPosition !== null && validPosition !== undefined) {
      this.validPosition = validPosition ?? this.validPosition;
      this.agentOutOfRange = !validPosition ?? this.agentOutOfRange;
    }
    if (agentAccountBalanceDate > this.agentAccountBalanceDate) {
      this.agentAccountActive = activeAccount ?? this.agentAccountActive;
      this.agentAccountBalanceDate = agentAccountBalanceDate;
      this.agentBalance = agentBalance ?? this.agentBalance;
      this.agentTotalBalance = agentTotalBalance ?? this.agentTotalBalance;
      this.balanceUse = this.agentBalance / this.agentTotalBalance;
      this.agentBalanceText = formatNumber(this.agentBalance);
      this.agentTotalBalanceText = formatNumber(this.agentTotalBalance);
    }
    this.refreshAgentInfo();
  }
}
