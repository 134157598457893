import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldComponent } from 'tuain-ng-forms-lib';

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSelectComponent extends FieldComponent {
  displayType: string;

  override start() {
    super.start();
    if (this.customAttributes().sortProcess === 'client') {
      const options = [...this.field?.options];
      options.sort((a, b) => {
        if (a.fieldOptionValue > b.fieldOptionValue) { return 1; }
        if (a.fieldOptionValue < b.fieldOptionValue) { return -1; }
        return 0;
      });
      this.options.set(options);
    }
  }

  filterInput(inputText, option) {
    const upperCaseInput = inputText.toUpperCase();
    const { nzLabel, nzValue, nzDisabled, nzHide } = option;
    return (!nzDisabled && !nzHide && (nzLabel?.toString()?.toUpperCase().includes(upperCaseInput)
      || nzValue?.toString()?.toUpperCase().includes(upperCaseInput)));
  }
}
