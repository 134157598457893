/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { AppConfigService } from './app-configuration.service';
import { standardRoutes, formRoutes } from '../routes-config/app-routes';

const DEFAULT_PATHS = ['', '/:token', '/:token/:subject'];

export function layoutModules(layoutName) {
  return Object.keys(formRoutes)
    .filter(name => formRoutes?.[name]?.layout === layoutName)
    .map((name) => {
      const route: Route = { path: formRoutes?.[name]?.root ?? '', loadChildren: formRoutes?.[name]?.loadChildren };
      return route;
    });
}

export function pathFunctions(path, debug = false) {
  const [, modulePath, componentPath] = path.split('/');
  const moduleName = Object.keys(formRoutes).find(module => formRoutes?.[module].root === modulePath);
  if (!moduleName) {
    return null;
  }
  const moduleForms = formRoutes?.[moduleName]?.childForms || [];
  const moduleFormsNames = Object.keys(moduleForms);
  const componentName = moduleFormsNames.find(
    formName => moduleForms[formName].route === componentPath,
  );
  return moduleForms[componentName]?.functions;
}

export function formRoute(formCode, debug = false) {
  const formName = standardRoutes?.[formCode] ?? formCode;
  if (!formName) {
    return;
  }
  let moduleRoute = '';
  let pageRoute = '';
  if (formName) {
    const moduleNames = Object.keys(formRoutes);
    for (const moduleName of moduleNames) {
      moduleRoute = formRoutes?.[moduleName]?.root ?? '';
      const moduleForms = formRoutes?.[moduleName]?.childForms ?? [];
      if (formName in moduleForms) {
        pageRoute = moduleForms[formName].route ?? '';
        break;
      }
    }
  }
  if (!moduleRoute || !pageRoute) {
    debug && console.log(`No logramos encontrar ${formName}`);
    return '';
  }
  return `${moduleRoute}/${pageRoute}`;
}

export function fullRouteForm(route, debug = false): any {
  const [, moduleRoute, pageRoute] = route.split('/');
  const module = Object.values(formRoutes).find(item => item.root === moduleRoute);
  const [formCode] = module ? Object.entries(module.childForms)
    .find(([, formDef]) => formDef?.route === pageRoute) : [];
  if (!formCode) {
    debug && console.log(`B. No logramos encontrar ${moduleRoute}/${pageRoute}`);
    return '';
  }
  return { moduleRoute, pageRoute, module, formCode };
}

export function routeForm(route, debug = false) {
  const { formCode } = fullRouteForm(route, debug);
  return formCode;
}

export function moduleRoot(moduleName) {
  return formRoutes?.[moduleName]?.root ?? '';
}

export function buildModelRoutes(moduleName, moduleComponents, authGuard) {
  const routes: Routes = [];
  const moduleForms = formRoutes?.[moduleName]?.childForms ?? [];
  for (const formName of Object.keys(moduleComponents)) {
    const formComponent = moduleForms?.[formName] ?? null;
    const comnponentPaths = DEFAULT_PATHS;
    comnponentPaths.forEach((formPath) => {
      const componentRoute: any = {
        path: `${formComponent?.route}${formPath}`,
        component: moduleComponents[formName],
        data: formComponent?.data,
      };
      if (authGuard && formComponent?.requireAuth) {
        componentRoute.canActivate = [authGuard];
      }
      routes.push(componentRoute);
    });
  }
  return routes;
}

@Injectable({ providedIn: 'root' })
export class ComponentPageService {
  constructor(private _appConfig: AppConfigService) { }

  pathFunctions(path) {
    const debug = this._appConfig.getParameter('debug');
    return pathFunctions(path, debug);
  }

  formRoute(formName) {
    const debug = this._appConfig.getParameter('debug');
    return formRoute(formName, debug);
  }

  routeForm(route) {
    const debug = this._appConfig.getParameter('debug');
    return routeForm(route, debug);
  }
}
