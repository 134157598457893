import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-swiper-slides',
  templateUrl: './swiper-slides.component.html',
  styleUrls: ['./swiper-slides.component.scss']
})

export class SwiperSlidesComponent {
  @Input() slideObjects: any[] = [];
  @Input() swiperProperties: any = [];
}