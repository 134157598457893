import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AntdModule } from 'src/app/antd.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleMapsModule } from '@angular/google-maps';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaskitoDirective } from '@maskito/angular';
import { NgChartsModule } from 'ng2-charts';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxEchartsModule } from 'ngx-echarts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QRCodeModule } from 'angularx-qrcode';
import { TuainNgFormsLibModule } from 'tuain-ng-forms-lib';

import { CustomComponentsModule } from '../custom/custom-component.module';

import { AppActionsFooter } from './layout/actions-footer/actions.footer.component';
import { AppElementComponent } from './layout/element/app-element.component';
import { AppEndUserFormBrandedHeaderComponent } from './layout/eu-branded-header/app-eu-form-branded-header.component';
import { AppFormErrorComponent } from './layout/error-header/app-form-error.component';
import { AppFormHeaderComponent } from './layout/header/app-form-header.component';
import { AppSectionComponent } from './layout/section/app-section.component';
import { AppSubSectionComponent } from './layout/section/app-subsection.component';

import { AppActionComponent } from './actions/app-action/app-action.component';

import { AppAlphaNumericComponent } from './fields/alpha-numeric/app-alpha-numeric.component';
import { AppArrayComponent } from './fields/array/app-array.component';
import { AppAvatarComponent } from './fields/avatar/app-avatar.component';
import { AppBarCodeComponent } from './fields/barcode/app-barcode.component';
import { AppCheckComponent } from './fields/check/app-check.component';
import { AppCiiuComponent } from './fields/ciiu/app-ciiu.component';
import { AppCityComponent } from './fields/city/app-city.component';
import { AppCountryComponent } from './fields/country/app-country.component';
import { AppCurrencyComponent } from './fields/currency/app-currency.component';
import { AppDateComponent } from './fields/date/app-date.component';
import { AppDateMonthComponent } from './fields/date-month/app-date-month.component';
import { AppDateRangeComponent } from './fields/date-range/app-date-range.component';
import { AppEmailComponent } from './fields/email/app-email.component';
import { AppFieldComponent } from './fields/app-field/app-field.component';
import { AppFileUploadComponent } from './fields/file-upload/file-upload.component';
import { AppImageComponent } from './fields/image/app-image.component';
import { AppInputComponent } from './fields/input/app-input.component';
import { AppInputSearchComponent } from './fields/input-search/app-input-search.component';
import { AppLabelComponent } from './fields/static/app-label.component';
import { AppLinkComponent } from './fields/static/app-link.component';
import { AppMapComponent } from './fields/map/app-map.component';
import { AppMessageComponent } from './fields/static/app-message.component';
import { AppNumberComponent } from './fields/number/app-number.component';
import { AppOtpInputComponent } from './fields/otp-input/app-otp-input.component';
import { AppPasswordComponent } from './fields/password/app-password.component';
import { AppPdfComponent } from './fields/pdf-viewer/app-pdf-viewer.component';
import { AppPhoneComponent } from './fields/phone/app-phone.component';
import { AppProgressComponent } from './fields/progress/app-progress.component';
import { AppQRCodeComponent } from './fields/qrcode/app-qrcode.component';
import { AppRadioComponent } from './fields/radio/app-radio.component';
import { AppRestrictedImageComponent } from './fields/restricted-image/app-restricted-image.component';
import { AppSegmentComponent } from './fields/segment/app-segment.component';
import { AppSelectComponent } from './fields/select/app-select.component';
import { AppTextAreaComponent } from './fields/textarea/app-textarea.component';
import { AppTextFormatComponent } from './fields/text-format/app-text-format.component';
import { AppTextHtmlComponent } from './fields/static/app-text-html.component';
import { AppTextNumberComponent } from './fields/text-number/app-text-number.component';
import { AppTimeComponent } from './fields/time/app-time.component';
import { AppTimeRangeComponent } from './fields/time-range/app-time-range.component';
import { AppTimeRangePckrsComponent } from './fields/time-range-pckrs/app-timerange-pckrs.component';
import { AppTimeRangeSliderComponent } from './fields/time-range-slider/app-time-range-slider.component';
import { AppTreeArrayComponent } from './fields/tree-array/app-tree-array.component';
import { AppTreeSelectComponent } from './fields/tree-select/app-tree-select.component';
import { AppTypeAheadComponent } from './fields/typeahead/app-typeahead.component';
import { AppWarningComponent } from './fields/static/app-warning.component';
import { AppYearMonthComponent } from './fields/yearmonth/app-yearmonth.component';

import { AppChartTableComponent } from './tables/chart-table/app-chart-table.component';
import { AppEChartTableComponent } from './tables/echart-table/app-echart-table.component';
import { AppGridTableComponent } from './tables/grid-table/app-grid-table.component';
import { AppKeyValueTableComponent } from './tables/key-values/app-key-values-table..component';
import { AppMapTableComponent } from './tables/map-table/app-map-table.component';
import { AppMessageListTableComponent } from './tables/message-list-table/app-message-list-table.component';
import { AppMessageTableComponent } from './tables/message-table/app-message-table.component';
import { AppMultiLineTableComponent } from './tables/multi-line-table/app-multi-line-table.component';
import { AppPieTableComponent } from './tables/pie-table/app-pie-table.component';
import { AppTableActionsDropdownComponent } from './tables/actions/actions-dropdown/actions-dropdown.component';
import { AppTableComponent } from './tables/app-table/app-table.component';
import { AppTableInlineActionComponent } from './tables/actions/inline-action/inline-action.component';
import { AppTableRecordActionComponent } from './tables/actions/row-action/row-action.component';
import { AppTableRecordFieldComponent } from './tables/fields/row-field.component';
import { AppTimeLineTableComponent } from './tables/time-line-table/app-time-line-table.component';
import { AppTriColumnTableComponent } from './tables/multi-line-table/app-tri-column-table.component';

import { StandardModalComponent, UploadModalComponent, ImageUploadModalComponent } from './forms/modals/standard-modals.component';

import { AppFormComponent } from './forms/app-form.component';

const COMPONENTS = [
  AppActionsFooter,
  AppElementComponent,
  AppEndUserFormBrandedHeaderComponent,
  AppFormErrorComponent,
  AppFormHeaderComponent,
  AppSectionComponent,
  AppSubSectionComponent,
  AppActionComponent,
  AppAlphaNumericComponent,
  AppArrayComponent,
  AppAvatarComponent,
  AppBarCodeComponent,
  AppCheckComponent,
  AppCiiuComponent,
  AppCityComponent,
  AppCountryComponent,
  AppCurrencyComponent,
  AppDateComponent,
  AppDateMonthComponent,
  AppDateRangeComponent,
  AppEmailComponent,
  AppFieldComponent,
  AppFileUploadComponent,
  AppImageComponent,
  AppInputComponent,
  AppInputSearchComponent,
  AppLabelComponent,
  AppLinkComponent,
  AppMapComponent,
  AppMessageComponent,
  AppNumberComponent,
  AppOtpInputComponent,
  AppPasswordComponent,
  AppPdfComponent,
  AppPhoneComponent,
  AppProgressComponent,
  AppQRCodeComponent,
  AppRadioComponent,
  AppRestrictedImageComponent,
  AppSegmentComponent,
  AppSelectComponent,
  AppTextAreaComponent,
  AppTextFormatComponent,
  AppTextHtmlComponent,
  AppTextNumberComponent,
  AppTimeComponent,
  AppTimeRangeComponent,
  AppTimeRangePckrsComponent,
  AppTimeRangeSliderComponent,
  AppTreeArrayComponent,
  AppTreeSelectComponent,
  AppTypeAheadComponent,
  AppWarningComponent,
  AppYearMonthComponent,
  AppChartTableComponent,
  AppEChartTableComponent,
  AppGridTableComponent,
  AppKeyValueTableComponent,
  AppMapTableComponent,
  AppMessageListTableComponent,
  AppMessageTableComponent,
  AppMultiLineTableComponent,
  AppPieTableComponent,
  AppTableActionsDropdownComponent,
  AppTableComponent,
  AppTableInlineActionComponent,
  AppTableRecordActionComponent,
  AppTableRecordFieldComponent,
  AppTimeLineTableComponent,
  AppTriColumnTableComponent,
  StandardModalComponent,
  UploadModalComponent,
  ImageUploadModalComponent,
  AppFormComponent,
  AppEndUserFormBrandedHeaderComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PdfViewerModule,
    FontAwesomeModule,
    AntdModule,
    MaskitoDirective,
    NgxDropzoneModule,
    ImageCropperModule,
    NgChartsModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    TuainNgFormsLibModule,
    CustomComponentsModule,
    GoogleMapsModule,
    NgxBarcode6Module,
    QRCodeModule,
    NgOtpInputModule,
  ],
  declarations: COMPONENTS,
  exports: [TuainNgFormsLibModule, ...COMPONENTS],
  bootstrap: [],
  schemas: [],
})
export class TuainComponentsModule { }
