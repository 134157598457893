<nz-form-item>
  <ion-icon *ngIf="includeInfo" (click)="dialog.showModal()" class="includeInfoIcon" name="information-circle-outline">
  </ion-icon>
  <nz-form-control [nzValidateStatus]="onValidation() ? 'validating' : errorType() ?? ''" [nzErrorTip]="errorMessage()">
    <nz-input-group [nzAddOnAfter]="" [nzPrefixIcon]="prefixIcon" [nzSuffix]="suffixIconName ? suffixIcon : ''">
      <input
        nz-input
        [id]="field.code"
        [(ngModel)]="value"
        (change)="inputChanged()"
        (keyup)="inputTypedCheck()"
        (keyup.enter)="inputChanged()"
        [readOnly]="disabled()"
        [placeholder]="placeholder()"
        [attr.maxlength]="maxLength()"
        autocomplete="off"
        [disabled]="disabled()" />
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
<ng-template #suffixIcon>
  <app-icon [iconName]="suffixIconName"></app-icon>
</ng-template>

<dialog #dialog class="dialogInfo">
  <div>
    <button (click)="dialog.close()">
      <ion-icon *ngIf="includeInfo" (click)="dialog.showModal()" name="close-circle" size="large"></ion-icon>
    </button>
  </div>
  <img [src]="infoContent?.message" />
</dialog>
